// React
import React from 'react';
import PropTypes from 'prop-types';

// MUI
import { Box, Divider, Typography, Grid, Stack, Button, Avatar } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';

// Components
// import moment from 'moment/moment';
import Iconify from '../../../../components/iconify/Iconify';
import { phCurrency } from '../../../../utils/formatNumber';

UnpaidBilling.propTypes = {
  handleClose: PropTypes.func,
  patient: PropTypes.object,
};

function UnpaidBilling({ handleClose, patient }) {
  const theme = useTheme();
  console.log('patient', patient);

  // Array
  const LAI = [
    {
      data: 'Name',
      icon: 'ph:dot-duotone',
      value: 'SampleName',
      // patient.patient_details?.full_name || patient?.first_name
      //   ? `${patient?.last_name}, ${patient?.first_name}, ${patient?.middle_name},`
      //   : `${patient?.patient_details?.last_name}, ${patient?.patient_details?.first_name} ${patient?.patient_details.middle_name}`,
    },
    {
      data: 'Patient No',
      icon: 'ph:dot-duotone',
      value: '000000002',

      //   value: patient.patient_details?.patient_no || patient.patient_no,
    },
    {
      data: 'Patient Type',
      icon: 'ph:dot-duotone',
      value: 'In Patient',

      //   value:
      //     patient.patient_details?.billing_unpaid?.patient_type ||
      //     patient.billing_unpaid?.patient_type,
    },
    {
      data: 'Date Admitted',
      icon: 'ph:dot-duotone',
      value: 'May 30, 2024 11:05 AM',

      //   value: moment(
      //     patient?.patient_details?.wards?.date_admitted ||
      //       patient?.billing_unpaid?.wards?.date_admitted ||
      //       patient?.patient_details?.billing_unpaid?.clinic?.date_admitted ||
      //       patient?.billing_unpaid?.clinic?.date_admitted
      //   ).format('LLL'),
    },
    {
      data: 'Date Discharged',
      icon: 'ph:dot-duotone',
      value: 'May 8, 2024 10:39 PM',

      //   value: moment(
      //     patient.patient_details?.created_at || patient.billing_unpaid?.created_at
      //   ).format('LLL'),
    },
    {
      data: 'Billing',
      icon: 'ph:dot-duotone',
      value: 'BN.00000001',
      // patient.patient_details?.billing_unpaid?.billing_no || patient.billing_unpaid?.billing_no,
      value2: '₱4,454.00',
      //    phCurrency(
      //     patient.patient_details?.billing_unpaid?.total_amount ||
      //       patient.billing_unpaid?.total_amount
      //   ),
    },
  ];

  return (
    <Box sx={{ m: 4 }}>
      <Grid container spacing={1} sx={{ mb: 2, alignItems: 'center' }}>
        <Grid item md={10}>
          <Typography variant="h4">Unpaid Billing Detected</Typography>
        </Grid>
        <Grid
          item
          md={2}
          sx={{ display: 'flex', justifyContent: 'flex-end', justifyItems: 'flex-end' }}
        >
          &nbsp;
        </Grid>
      </Grid>

      <Divider />

      <Grid container spacing={1}>
        <Grid item md={12} sx={{ mt: 2 }}>
          <Box sx={{ p: 2, bgcolor: 'rgba(211, 211, 211, 0.15)', borderRadius: '12px' }}>
            <Stack>
              {LAI.map((option) => (
                <>
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ textAlign: 'center', alignItems: 'center', mt: 1, mb: 1 }}
                  >
                    <Grid container spacing={1} alignItems="center">
                      <Grid item md={2} display="flex" justifyContent="center">
                        <Iconify
                          icon={option.icon}
                          width={30}
                          height={30}
                          sx={{ color: theme.palette.primary.dark }}
                        />
                      </Grid>
                      <Grid item md={10} display="flex" justifyContent="flex-start">
                        <Typography variant="h6" sx={{ mr: 2 }}>
                          {option.data}:{' '}
                        </Typography>
                        <Typography variant="h6">{option.value}</Typography>
                        {option.value2 && (
                          <>
                            <br />
                            <Typography variant="h6" sx={{ ml: 2 }}>
                              {option.value2}
                            </Typography>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Stack>
                </>
              ))}
            </Stack>
          </Box>
        </Grid>

        <Grid item md={12}>
          <Stack
            spacing={1}
            direction="row"
            sx={{
              mt: 2,
              justifyContent: 'flex-end',
              justifyItems: 'flex-end',
              alignItems: 'flex-end',
            }}
          >
            {/* <Button variant="contained" color="primary" onClick={handleClose}>
              Admit Patient
            </Button> */}
            <Button variant="outlined" color="inherit" onClick={handleClose}>
              Close
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

export default UnpaidBilling;

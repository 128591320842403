// React
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

// MUI
import { useTheme } from '@mui/material/styles';
import { Box, Divider, Typography, Grid, Stack, Button, IconButton } from '@mui/material';

// // Third Party
// import ReactToPrint from 'react-to-print';

// Components
import Iconify from '../../../components/iconify/Iconify';
import Scrollbar from '../../../components/scrollbar/Scrollbar';
import DispensaryGreenCardBack from './DispensaryGreenCardBack';
import DispensaryGreenCardFront from './DispensaryGreenCardFront';

// GreenCardPreview.propTypes = {
//   handleClose: PropTypes.func.isRequired,
//   data: PropTypes.object,
//   isView: PropTypes.bool,
// };

export default function GreenCardPreview() {
  // { data, handleClose, isView }
  //   const theme = useTheme();
  //   const componentRef = useRef();

  return (
    <>
      <Box>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="h4">Green Card Preview</Typography>
              </Grid>

              <Grid
                item
                xs={2}
                sm={4}
                md={6}
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
              >
                <IconButton
                  size="small"
                  sx={{ border: '2px solid', color: '#000000', mb: 1 }}
                  //   onClick={handleClose}
                >
                  <Iconify icon="ion:close" width={30} height={30} sx={{ color: '#000000' }} />
                </IconButton>
              </Grid>
            </Grid>
            <Divider />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            {/* <div ref={componentRef}>
              <style type="text/css" media="print">
                {`
              @page { size: landscape; margin-top: 60mm; }
              .hide-for-print { display: none; } 
              .hide-for-print { display: none; } 
            `}
              </style> */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box
                  className="Box_Front"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <DispensaryGreenCardFront
                  // form={data}
                  />
                  <Typography
                    sx={{ fontSize: '1rem', fontWeight: 'bold', textAlign: 'center' }}
                    className="hide-for-print"
                  >
                    Front ID
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  className="Box_Back"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <DispensaryGreenCardBack
                  // form={data}
                  />
                  <Typography
                    sx={{ fontSize: '1rem', fontWeight: 'bold', textAlign: 'center' }}
                    className="hide-for-print"
                  >
                    Back ID
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            {/* </div> */}
          </Grid>
          {/* {!isView ? ( */}
          <Grid item xs={12} sm={12} md={12}>
            <Stack
              spacing={1}
              direction="row"
              sx={{
                justifyContent: 'flex-end',
                justifyItems: 'flex-end',
                alignItems: 'flex-end',
              }}
            >
              {/* <ReactToPrint
                  trigger={() => (
                    <Button variant="contained" color="secondary">
                      Print
                    </Button>
                  )}
                  content={() => componentRef.current}
                  documentTitle={`${data?.patient_no} Green Card ID`}
                  
                /> */}
            </Stack>
          </Grid>
          {/* ) : null} */}
        </Grid>
      </Box>
    </>
  );
}

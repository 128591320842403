// React
import React from 'react';
import PropTypes from 'prop-types';

// MUI
import { Box, Typography, Stack } from '@mui/material';

// Components
import Image from 'mui-image';
import Iconify from '../../../components/iconify/Iconify';

// Util
// import { dateShortMonth } from '../../../../utils/formatTime';

function DispensaryGreenCardFront() {
  // { form }
  const styles = {
    paperContainer: {
      backgroundImage: `url(${'/assets/images/HIS/cropped_cnph_opacity.png'})`,
      backgroundColor: '#065027',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      width: '100mm',
      height: '60mm',
    },
  };

  //   const address = [
  //     form?.street,
  //     form?.house_number,
  //     form?.region,
  //     form?.province,
  //     form?.city,
  //     form?.barangay,
  //   ]
  //     .filter((value) => value !== 'undefined')
  //     .join(' ');

  //   const regexUrl = /\b(http|https)/;
  //   const imageProfile = regexUrl.test(form?.patient_image)
  //     ? form?.patient_image
  //     : `${process.env.REACT_APP_HOST_API_KEY}${form?.patient_image}`;

  return (
    <>
      <Box style={styles.paperContainer} sx={{ borderRadius: '16px' }}>
        <Box sx={{ color: 'white', p: 1 }}>
          <Stack direction="row" spacing={5}>
            <Image src="/assets/images/HIS/camnor.png" height="20%" width="20%" />
            <Box>
              <Typography sx={{ fontSize: '0.8rem', fontWeight: 'bold', textAlign: 'center' }}>
                CAMARINES NORTE PROVINCIAL HOSPITAL
              </Typography>
              <Typography sx={{ fontSize: '0.6rem', textAlign: 'center' }}>
                Daet, Camarines Norte
              </Typography>
            </Box>
            <Image src="/assets/images/HIS/health-office.png" height="20%" width="20%" />
          </Stack>
        </Box>

        <Box sx={{ color: 'white', p: 2, pt: 0 }}>
          <Stack direction="row" spacing={2}>
            <Box>
              {/* {form?.patient_image ? ( */}
              <Image src="/assets/images/HIS/camnorte_seal.png" height="80px" width="80px" />
              {/* ) : ( */}
              {/* <Iconify
                icon="iconamoon:profile-fill"
                sx={{
                  width: '80px',
                  height: '80px',
                  ml: 0.5,
                  border: '1px solid #ccc',
                  backgroundColor: 'rgba(204, 204, 204, 0.9)',
                }}
              /> */}
              {/* )} */}

              <Typography
                sx={{
                  fontSize: '0.6rem',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  position: 'absolute',
                }}
              >
                CNPH-222222
                {/* {form?.patient_no} */}
              </Typography>
            </Box>
            <Box>
              <Stack spacing={2}>
                <Box>
                  <Typography sx={{ fontSize: '0.6rem' }}>Last Name, First Name, Suffix</Typography>
                  <Typography sx={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
                    Doe, Jonathan
                    {/* {form?.last_name}, {form?.first_name} {form?.middle_name} */}
                  </Typography>
                  <Typography sx={{ fontSize: '0.6rem' }}> Middle Initial</Typography>
                  <Typography sx={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
                    Desmond.
                    {/* {form?.middle_name}. */}
                  </Typography>
                  <Typography sx={{ fontSize: '0.5rem' }}> Birthday</Typography>
                  <Typography sx={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
                    May 06 1998
                    {/* {dateShortMonth(form?.birth_date)} */}
                  </Typography>
                  <Typography sx={{ fontSize: '0.4rem' }}> Address</Typography>
                  <Typography
                    sx={{
                      fontSize: '0.5rem',
                      fontWeight: 'bold',
                      width: '40ch',
                      overflowWrap: 'break-word',
                    }}
                  >
                    {/* {(form?.address !== null && `${form?.address[0]} ${form?.address[1]} ${form?.address[2]}`) ||
                      `${form?.street}  ${form?.house_number} ${form?.region}, ${form?.province} , ${form?.city}, ${form?.barangay}   `} */}
                    ---------------------
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </Box>
        <Box sx={{ color: 'white', p: 2, position: 'absolute', mt: -3 }}>
          <Stack direction="row" spacing={24.8}>
            <Box>
              <Box sx={{ backgroundColor: '#fff', width: '70px', height: '15px' }}>&nbsp;</Box>
              <Typography sx={{ fontSize: '0.4rem', textAlign: 'center' }}>
                Holder&apos;s Signature
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: '0.6rem', fontWeight: 'bold', textAlign: 'center' }}>
                Ricarte R. Padilla
              </Typography>
              <Typography sx={{ fontSize: '0.4rem', textAlign: 'center' }}>
                Camarines Norte Governor
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Box>
    </>
  );
}

export default DispensaryGreenCardFront;

import React from 'react';
// import { useTheme } from '@mui/material/styles';
// import { color } from '@mui/system';

import {
  // Box,
  Divider,
  Typography,
  Grid,
  Stack,
  Button,
  // Avatar,
  TextField,
  FormControlLabel,
  Radio,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  Checkbox,
} from '@mui/material';
// COMPONENTS
// import Label from '../../../../components/label/Label';
import Scrollbar from '../../../../components/scrollbar/Scrollbar';

function Edit() {
  return (
    <>
      <Grid container spacing={1} sx={{ mb: 2, alignItems: 'center' }}>
        <Grid item md={5}>
          <Typography variant="h5">Edit Triage Record</Typography>
        </Grid>
        <Grid
          item
          md={4}
          sx={{ display: 'flex', justifyContent: 'flex-end', justifyItems: 'flex-end' }}
        >
          {/* <RHFRadioGroupCustomize name="patient_type" defaultValue={data.patient_type}>
            <FormControlLabel value="In-Patient" control={<Radio />} label="In-Patient" />
            <FormControlLabel value="Out-Patient" control={<Radio />} label="Out-Patient" />
        </RHFRadioGroupCustomize> */}
        </Grid>
        <Grid item md={3} sx={{ justifyContent: 'flex-end', justifyItems: 'flex-end' }}>
          <InputLabel>Priority</InputLabel>

          <Select fullWidth label="Type" name="Priority">
            <MenuItem key="Confidential" value="Confidential">
              Confidential
            </MenuItem>
            <MenuItem key="V.I.P" value="V.I.P">
              V.I.P
            </MenuItem>
            <MenuItem key="Senior Citizen/ Pregnant / PWD" value="Senior Citizen/ Pregnant / PWD">
              Senior Citizen/ Pregnant / PWD
            </MenuItem>
            <MenuItem key="Pay" value="Pay">
              Pay
            </MenuItem>
            <MenuItem key="Charity" value="Charity">
              Charity
            </MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Divider />
      <Scrollbar sx={{ maxHeight: 500 }}>
        <Grid row item md={6} sx={{ display: 'grid', justifyContent: 'center' }}>
          <Grid container spacing={1} sx={{ mt: 1, alignItems: 'center' }}>
            <Grid item md={4} sx={{ mt: 1 }}>
              <TextField
                id="last_name"
                name="last_name"
                label="Last Name"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item md={4} sx={{ mt: 1 }}>
              <TextField
                id="first_name"
                name="first_name"
                label="First Name"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item md={4} sx={{ mt: 1 }}>
              <TextField
                id="middle_name"
                name="middle_name"
                label="Middle Name"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item md={2} sx={{ mt: 2.7 }}>
              <TextField id="age" name="age" label="Age" variant="outlined" />
            </Grid>
            <Grid item md={2} sx={{ justifyContent: 'flex-end', justifyItems: 'flex-end' }}>
              <InputLabel>Gender</InputLabel>
              <Select fullWidth label="Type" name="Priority">
                <MenuItem key="Male" value="Male">
                  Male
                </MenuItem>
                <MenuItem key="Female" value="Female">
                  Female
                </MenuItem>
              </Select>
            </Grid>

            <Grid item md={4}>
              <InputLabel>Status</InputLabel>
              <Select fullWidth label="DOT" name="Priority">
                <MenuItem key="Confidential" value="Confidential">
                  Confidential
                </MenuItem>
                <MenuItem key="V.I.P" value="V.I.P">
                  V.I.P
                </MenuItem>
                <MenuItem
                  key="Senior Citizen/ Pregnant / PWD"
                  value="Senior Citizen/ Pregnant / PWD"
                >
                  Senior Citizen/ Pregnant / PWD
                </MenuItem>
                <MenuItem key="Pay" value="Pay">
                  Pay
                </MenuItem>
                <MenuItem key="Charity" value="Charity">
                  Charity
                </MenuItem>
              </Select>
            </Grid>
            <Grid item md={4} sx={{ mt: 2.7 }}>
              <TextField
                id="age"
                name="Date of Birth"
                label="Date of Birth"
                variant="outlined"
                disabled
                fullWidth
              />
            </Grid>

            <Grid item md={4} sx={{ mt: 2.7 }}>
              <TextField
                id="age"
                name="House Number"
                label="House Number"
                variant="outlined"
                disabled
                fullWidth
              />
            </Grid>
            <Grid item md={4} sx={{ mt: 2.7 }}>
              <TextField
                id="age"
                name="Street"
                label="Street"
                variant="outlined"
                disabled
                fullWidth
              />
            </Grid>
            <Grid item md={4}>
              <InputLabel>Region</InputLabel>
              <Select fullWidth label="Regions">
                <MenuItem key="Confidential" value="Confidential">
                  Region I- Ilocos Region{' '}
                </MenuItem>
                <MenuItem key="V.I.P" value="V.I.P">
                  Region II – Cagayan Valley{' '}
                </MenuItem>
                <MenuItem
                  key="Senior Citizen/ Pregnant / PWD"
                  value="Senior Citizen/ Pregnant / PWD"
                >
                  Region III – Central Luzon
                </MenuItem>
                <MenuItem key="Pay" value="Pay">
                  Region IV‑A – CALABARZON
                </MenuItem>
                <MenuItem key="Charity" value="Charity">
                  Region V – Bicol Region
                </MenuItem>
                <MenuItem key="Charity" value="Charity">
                  Region VI – Western Visayas
                </MenuItem>
                <MenuItem key="Charity" value="Charity">
                  Region VII – Central Visayas
                </MenuItem>
                <MenuItem key="Charity" value="Charity">
                  Region VII – Eastern Visayas
                </MenuItem>

                <MenuItem key="Charity" value="Charity">
                  Region IX – Zamboanga Peninsula{' '}
                </MenuItem>
                <MenuItem key="Charity" value="Charity">
                  Region X – Northern Mindanao{' '}
                </MenuItem>
                <MenuItem key="Charity" value="Charity">
                  Region XI – Davao Region{' '}
                </MenuItem>
                <MenuItem key="Charity" value="Charity">
                  Region XII – SOCCSKSARGEN{' '}
                </MenuItem>
                <MenuItem key="Charity" value="Charity">
                  CAR – Cordillera Administrative Region
                </MenuItem>
                <MenuItem key="Charity" value="Charity">
                  BARMM – Bangsamoro Autonomous Region in Muslim Mindanao
                </MenuItem>
              </Select>
            </Grid>
            <Grid item md={4}>
              <InputLabel>Province</InputLabel>
              <Select fullWidth label="Type" name="Priority">
                <MenuItem key="Male" value="Male">
                  Insert Province
                </MenuItem>
                <MenuItem key="Female" value="Female">
                  Insert Province
                </MenuItem>
              </Select>
            </Grid>
            <Grid item md={4}>
              <InputLabel>City</InputLabel>
              <Select fullWidth label="Type" name="Priority">
                <MenuItem key="Male" value="Male">
                  Insert City
                </MenuItem>
                <MenuItem key="Female" value="Female">
                  Insert City
                </MenuItem>
              </Select>
            </Grid>
            <Grid item md={4}>
              <InputLabel>Baranggay</InputLabel>
              <Select fullWidth label="Type" name="Priority">
                <MenuItem key="Male" value="Male">
                  Insert Baranggay
                </MenuItem>
                <MenuItem key="Female" value="Female">
                  Insert Baranggay
                </MenuItem>
              </Select>
            </Grid>

            <Grid item md={12} sx={{ mt: 2 }}>
              <Typography variant="h6">Referred By:</Typography>
              <Grid container spacing={1} sx={{ mt: 1 }}>
                <Grid row item md={6} sx={{ display: 'grid', justifyContent: 'center' }}>
                  {/* <RHFRadioGroup  name="referred_by" defaultValue="self" sx={{ width: '50%' }} options={REFERRED_TYPE_OPTIONS}/> */}

                  <RadioGroup row name="referred_by" container spacing={1}>
                    <Grid item md={4} sx={{ display: 'grid', justifyContent: 'center' }}>
                      <Grid item md={12}>
                        <FormControlLabel
                          value="self"
                          control={<Radio />}
                          label={<Typography variant="body1">Self</Typography>}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <FormControlLabel
                          value="opd"
                          control={<Radio />}
                          label={<Typography variant="body1">OPD</Typography>}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <FormControlLabel
                          value="pri-vmd"
                          control={<Radio />}
                          label={<Typography variant="body1">Priv MD</Typography>}
                        />
                      </Grid>
                    </Grid>
                    <Grid item md={4} sx={{ display: 'grid', justifyContent: 'center' }}>
                      <Grid item md={12}>
                        <FormControlLabel
                          value="hospital"
                          control={<Radio />}
                          label={<Typography variant="body1">Hospital</Typography>}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <FormControlLabel
                          value="ambulance"
                          control={<Radio />}
                          label={<Typography variant="body1">Ambulance</Typography>}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <FormControlLabel
                          value="walk-in"
                          control={<Radio />}
                          label={<Typography variant="body1">Walk-In</Typography>}
                        />
                      </Grid>
                    </Grid>
                    <Grid item md={4} sx={{ display: 'grid', justifyContent: 'center' }}>
                      <Grid item md={12}>
                        <FormControlLabel
                          value="private-vehicle"
                          control={<Radio />}
                          label={<Typography variant="body1">Private Vehicle</Typography>}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <FormControlLabel
                          value="police-escort"
                          control={<Radio />}
                          label={<Typography variant="body1">Police Escort</Typography>}
                        />
                      </Grid>
                      <Grid item md={12} sx={{ mt: 1 }}>
                        <TextField label="Other" />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </Grid>
                <Grid item md={6} sx={{ display: 'grid', justifyContent: 'center' }}>
                  <Grid item md={12}>
                    <Typography variant="h6">SMOKING</Typography>
                  </Grid>
                  <RadioGroup row id="smoking" name="smoking" sx={{ width: '50%' }}>
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label={<Typography variant="body1">Yes</Typography>}
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label={<Typography variant="body1">No</Typography>}
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </Grid>
            {/* --------------------------------------------------------------------------- */}

            <Grid item md={12} sx={{ mt: 2 }}>
              <Typography variant="h6">Vital Signs:</Typography>
              <Grid container spacing={1} sx={{ mt: 1 }}>
                <Grid item md={6} sx={{ display: 'grid', justifyContent: 'center' }}>
                  <Grid container spacing={1}>
                    <Grid item md={12}>
                      <TextField
                        id="bp"
                        name="bp"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start">BP</InputAdornment>,
                          endAdornment: <InputAdornment position="end">mmHg</InputAdornment>,
                          inputProps: { style: { textAlign: 'center' } },
                        }}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <TextField
                        id="hr"
                        name="hr"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start">HR</InputAdornment>,
                          endAdornment: <InputAdornment position="end">bpm</InputAdornment>,
                          inputProps: { style: { textAlign: 'center' } },
                        }}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <TextField
                        id="rr"
                        name="rr"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start">RR</InputAdornment>,
                          endAdornment: <InputAdornment position="end">bpm</InputAdornment>,
                          inputProps: { style: { textAlign: 'center' } },
                        }}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <TextField
                        id="temp"
                        name="temp"
                        variant="outlined"
                        fullWidth
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">Temp</InputAdornment>,
                          endAdornment: <InputAdornment position="end">°C</InputAdornment>,
                          inputProps: { style: { textAlign: 'center' } },
                        }}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <TextField
                        id="weight"
                        name="weight"
                        variant="outlined"
                        fullWidth
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">Weight</InputAdornment>,
                          endAdornment: <InputAdornment position="end">kgs</InputAdornment>,
                          inputProps: { style: { textAlign: 'center' } },
                        }}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <TextField
                        id="height"
                        name="height"
                        variant="outlined"
                        fullWidth
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">Height</InputAdornment>,
                          endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                          inputProps: { style: { textAlign: 'center' } },
                        }}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <TextField
                        id="O2Sat"
                        name="O2sat"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start">O2 Sat</InputAdornment>,
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                          inputProps: { style: { textAlign: 'center' } },
                        }}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <Grid container spacing={1}>
                        <Grid item md={6}>
                          <TextField
                            id="g"
                            name="g"
                            variant="outlined"
                            fullWidth
                            InputProps={{
                              startAdornment: <InputAdornment position="start">G</InputAdornment>,
                              inputProps: { style: { textAlign: 'center' } },
                            }}
                          />
                        </Grid>
                        <Grid item md={6}>
                          <TextField
                            id="p"
                            name="p"
                            variant="outlined"
                            fullWidth
                            InputProps={{
                              startAdornment: <InputAdornment position="start">P</InputAdornment>,
                              inputProps: { style: { textAlign: 'center' } },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item md={6} sx={{ display: 'grid', justifyContent: 'center' }}>
                  <Grid container spacing={1}>
                    <Grid item md={12}>
                      <TextField
                        id="bp"
                        name="bp"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start">BP</InputAdornment>,
                          endAdornment: <InputAdornment position="end">mmHg</InputAdornment>,
                          inputProps: { style: { textAlign: 'center' } },
                        }}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <TextField
                        id="bp"
                        name="bp"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start">LMP</InputAdornment>,
                          // endAdornment: <InputAdornment position="end">mmHg</InputAdornment>,
                          inputProps: { style: { textAlign: 'center' } },
                        }}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <TextField
                        id="hr"
                        name="hr"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start">EDC</InputAdornment>,
                          // endAdornment: <InputAdornment position="end">bpm</InputAdornment>,
                          inputProps: { style: { textAlign: 'center' } },
                        }}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <TextField
                        id="rr"
                        name="rr"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start">AOG</InputAdornment>,
                          endAdornment: <InputAdornment position="end">wks</InputAdornment>,
                          inputProps: { style: { textAlign: 'center' } },
                        }}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <TextField
                        id="temp"
                        name="temp"
                        variant="outlined"
                        fullWidth
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">FHT</InputAdornment>,
                          endAdornment: <InputAdornment position="end">bpm</InputAdornment>,
                          inputProps: { style: { textAlign: 'center' } },
                        }}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <TextField
                        id="weight"
                        name="weight"
                        variant="outlined"
                        fullWidth
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">FH</InputAdornment>,
                          endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                          inputProps: { style: { textAlign: 'center' } },
                        }}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <TextField
                        id="height"
                        name="height"
                        variant="outlined"
                        fullWidth
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">IE</InputAdornment>,
                          endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                          inputProps: { style: { textAlign: 'center' } },
                        }}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <TextField
                        id="O2Sat"
                        name="O2sat"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">MENARCHE</InputAdornment>
                          ),
                          // endAdornment: <InputAdornment position="end">%</InputAdornment>,
                          inputProps: { style: { textAlign: 'center' } },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid item md={3} sx={{ display: 'grid', justifyContent: 'center' }}>
                <RadioGroup name="vital_signs" defaultValue="admits-tip">
                  <Grid item md={12} sx={{ display: 'grid', justifyContent: 'center' }}>
                    <Grid item md={12}>
                      <FormControlLabel
                        value="admits-tip"
                        control={<Radio />}
                        label={<Typography variant="body1">Admits Tip</Typography>}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <FormControlLabel
                        value="fully-dilated"
                        control={<Radio />}
                        label={<Typography variant="body1">Fully Dilated</Typography>}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <FormControlLabel
                        value="bow"
                        control={<Radio />}
                        label={<Typography variant="body1">BOW</Typography>}
                      />
                    </Grid>
                    <Grid item md={12}>
                      &nbsp;
                    </Grid>
                    <Grid item md={12}>
                      &nbsp;
                    </Grid>
                    <Grid item md={12}>
                      &nbsp;
                    </Grid>
                    <Grid item md={12}>
                      &nbsp;
                    </Grid>
                    <Grid item md={12}>
                      &nbsp;
                    </Grid>
                    <Grid item md={12}>
                      &nbsp;
                    </Grid>
                    <Grid item md={12}>
                      &nbsp;
                    </Grid>
                    <Grid item md={12}>
                      &nbsp;
                    </Grid>
                    <Grid item md={12}>
                      &nbsp;
                    </Grid>
                    <Grid item md={12}>
                      &nbsp;
                    </Grid>
                    <Grid item md={12}>
                      &nbsp;
                    </Grid>
                    <Grid item md={12}>
                      &nbsp;
                    </Grid>
                  </Grid>
                  <Grid item md={12} sx={{ display: 'grid', justifyContent: 'center' }}>
                    <Grid item md={12}>
                      <FormControlLabel
                        value="full-term"
                        control={<Radio />}
                        label={<Typography variant="body1">Full-Term</Typography>}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <FormControlLabel
                        value="pre-term"
                        control={<Radio />}
                        label={<Typography variant="body1">Pre-Term</Typography>}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <FormControlLabel
                        value="abortion"
                        control={<Radio />}
                        label={<Typography variant="body1">Abortion</Typography>}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <FormControlLabel
                        value="living"
                        control={<Radio />}
                        label={<Typography variant="body1">Living</Typography>}
                      />
                    </Grid>
                    <Grid item md={12}>
                      &nbsp;
                    </Grid>
                    <Grid item md={12}>
                      &nbsp;
                    </Grid>
                    <Grid item md={12}>
                      &nbsp;
                    </Grid>
                    <Grid item md={12}>
                      &nbsp;
                    </Grid>
                    <Grid item md={12}>
                      &nbsp;
                    </Grid>
                    <Grid item md={12}>
                      &nbsp;
                    </Grid>
                    <Grid item md={12}>
                      &nbsp;
                    </Grid>
                    <Grid item md={12}>
                      &nbsp;
                    </Grid>
                    <Grid item md={12}>
                      &nbsp;
                    </Grid>
                    <Grid item md={12}>
                      &nbsp;
                    </Grid>
                  </Grid>
                </RadioGroup>
              </Grid> */}
            </Grid>

            {/* --------------------------------------------- */}
            <Grid item md={12} sx={{ mt: 2 }}>
              <Typography variant="h6">Chief Complaint:</Typography>
              <Grid container spacing={1} sx={{ mt: 0.5 }}>
                <Grid item md={12}>
                  <TextField
                    id="chief_complaint"
                    name="chief_complaint"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={3}
                    maxRows={3}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* -------------------------------------------- */}
            <Grid item md={12} sx={{ mt: 2 }}>
              <Grid container spacing={1}>
                <Grid item md={4}>
                  <Typography variant="h6" sx={{ mb: 1 }}>
                    Past Medical:
                  </Typography>

                  <Grid item md={12}>
                    <Grid container spacing={1}>
                      {/* <RHFMultiCheckbox name="past_medical" options={FILTER_GENDER_OPTIONS} sx={{ width: 1 }} /> */}
                      {/* <RHFCheckbox  label="Protect from light" name="past_medical" /> */}

                      {/*       
                      <Grid item md={6}>
                        <FormControlLabel
                          value="none"
                          control={<Checkbox />}
                          label={<Typography variant="body1">None</Typography>}
                          sx={{ ml: 2 }}
                        />
                        <FormControlLabel
                          value="diabetes"
                          control={<Checkbox />}
                          label={<Typography variant="body1">Diabetes</Typography>}
                          sx={{ ml: 2 }}
                        />
                        <FormControlLabel
                          value="asthma"
                          control={<Checkbox />}
                          label={<Typography variant="body1">Asthma</Typography>}
                          sx={{ ml: 2 }}
                        />
                        <FormControlLabel
                          value="icu admission"
                          control={<Checkbox />}
                          label={<Typography variant="body1">ICU Admission</Typography>}
                          sx={{ ml: 2 }}
                        />
                      </Grid>
                      <Grid item md={6}>
                        <FormControlLabel
                          value="ptb"
                          control={<Checkbox />}
                          label={<Typography variant="body1">PTB</Typography>}
                          sx={{ ml: 2 }}
                        />
                        <FormControlLabel
                          value="hypertension"
                          control={<Checkbox />}
                          label={<Typography variant="body1">Hypertension</Typography>}
                          sx={{ ml: 2 }}
                        />
                        <FormControlLabel
                          value="cardiac"
                          control={<Checkbox />}
                          label={<Typography variant="body1">Cardiac</Typography>}
                          sx={{ ml: 2 }}
                        />
                        <FormControlLabel
                          value="other"
                          control={<Checkbox />}
                          label={<Typography variant="body1">Other</Typography>}
                          sx={{ ml: 2 }}
                        />
                      </Grid> 
                 
                      {/* <Grid item md={6}>
              <RHFMultiCheckbox
                row
                name="past_medical"
                // label="RHFMultiCheckbox"

                options={[
                  { value: 'none',   label:  <Typography variant="body1">None</Typography>},
                  { value: 'diabetes', label:  <Typography variant="body1">Diabetes</Typography>},
                  { value: 'asthma',   label:  <Typography variant="body1">Asthma</Typography>},
                  { value: 'icu admission',   label:  <Typography variant="body1">ICU Admission</Typography>},
                 
                ]}
              />
              
                </Grid> */}

                      {/* <Grid item md={6}>
              <RHFMultiCheckbox
                row
                name="past_medical"
                // label="RHFMultiCheckbox"

                options={[
                  { value: 'ptb',   label:  <Typography variant="body1">PTB</Typography>},
                  { value: 'hypertension', label:  <Typography variant="body1">Hypertension</Typography>},
                  { value: 'cardiac',   label:  <Typography variant="body1">Cardiac</Typography>},
                  { value: 'other',   label:  <Typography variant="body1">Other</Typography>},
                 
                ]}
              />
              
                </Grid> */}
                      {/* </RHFCheckbox> */}

                      <Grid item md={6}>
                        <FormControlLabel
                          value="none"
                          control={<Checkbox />}
                          label={<Typography variant="body1">None</Typography>}
                          // sx={{ ml: 2 }}
                        />
                        <FormControlLabel
                          value="diabetes"
                          control={<Checkbox />}
                          label={<Typography variant="body1">Diabetes</Typography>}
                          // sx={{ ml: 2 }}
                        />
                        <FormControlLabel
                          value="asthma"
                          control={<Checkbox />}
                          label={<Typography variant="body1">Asthma</Typography>}
                          // sx={{ ml: 2 }}
                        />
                        <FormControlLabel
                          value="icuadmission"
                          control={<Checkbox />}
                          label={<Typography variant="body1">ICU Admission</Typography>}
                          // sx={{ ml: 2 }}
                        />
                      </Grid>
                      <Grid item md={6}>
                        <FormControlLabel
                          value="ptb"
                          control={<Checkbox />}
                          label={<Typography variant="body1">PTB</Typography>}
                          // sx={{ ml: 2 }}
                        />
                        <FormControlLabel
                          value="hypertension"
                          control={<Checkbox />}
                          label={<Typography variant="body1">Hypertension</Typography>}
                          // sx={{ ml: 1 }}
                        />
                        <FormControlLabel
                          value="cardiac"
                          control={<Checkbox />}
                          label={<Typography variant="body1">Cardiac</Typography>}
                          // sx={{ ml: 2 }}
                        />
                        <FormControlLabel
                          value="other"
                          control={<Checkbox />}
                          label={<Typography variant="body1">Other</Typography>}
                          // sx={{ ml: 2 }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={8}>
                  <Typography variant="h6" sx={{ mb: 1 }}>
                    Medications:
                  </Typography>
                  <TextField
                    id="medication"
                    name="medication"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={6}
                    maxRows={5}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* ---------------------------------------------- */}
            <Grid item md={12} sx={{ mt: 4 }}>
              <Grid container spacing={1} sx={{ p: 1 }}>
                <Grid item md={4} sx={{ border: '1px solid gainsboro', p: 2, ml: 0.5 }}>
                  <Typography variant="subtitle1" sx={{ color: '#696969' }}>
                    Triage Officer:
                  </Typography>
                  <Typography variant="h5" sx={{ textAlign: 'center', mt: 1 }}>
                    DR. JAKE THE DOG
                  </Typography>
                </Grid>
                <Grid item md={4} sx={{ border: '1px solid gainsboro', p: 2 }}>
                  <Typography variant="subtitle1" sx={{ color: '#696969' }}>
                    Date:
                  </Typography>
                  <Typography variant="h5" sx={{ textAlign: 'center', mt: 1, color: '#483D8B' }}>
                    May 16,2024
                  </Typography>
                </Grid>
                <Grid item md={4} sx={{ border: '1px solid gainsboro', p: 2, mr: -1 }}>
                  <Typography variant="subtitle1" sx={{ color: '#696969' }}>
                    Time:
                  </Typography>
                  <Typography variant="h5" sx={{ textAlign: 'center', mt: 1 }}>
                    11:46 am
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* --------------------------------------------- */}
            <Grid item md={12}>
              <Stack
                spacing={1}
                direction="row"
                sx={{
                  mb: 4,
                  justifyContent: 'flex-end',
                  justifyItems: 'flex-end',
                  alignItems: 'flex-end',
                }}
              >
                <Button variant="outlined" color="inherit">
                  Close
                </Button>
                <Button type="submit" variant="contained">
                  Save Changes
                </Button>
              </Stack>
            </Grid>
            {/* --------------------------------------- */}
            {/*  */}
          </Grid>
        </Grid>
      </Scrollbar>
    </>
  );
}

export default Edit;

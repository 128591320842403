import React from 'react';
import Header from './Header/Header';
import ViewSignUp from './SignUp/ViewSignUp';
import ViewLogin from './Login/ViewLogin';
import ViewTriage from './Triage/ViewTriage';

function ManualHIS() {
  return (
    <>
      <Header />
      <ViewSignUp />
      <ViewLogin />
      <ViewTriage />
    </>
  );
}

export default ManualHIS;

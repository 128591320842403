import React from 'react';
import {
  Fab,
  Card,
  // Accordion,
  // AccordionSummary,
  // AccordionDetails,
  Typography,
  Stack,
  Container,
  Box,
  Button,
  Link,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Image } from 'mui-image';
// import ConfirmDialog from '../../../components/confirm-dialog/ConfirmDialog';
// import AddUser from './Components/AddUser';

function ViewLogin() {
  // HOOKS
  // const [openAD, setOpenAD] = useState(false);
  // const handleCloseAD = () => {
  //   setOpenAD(false);
  // };
  // const handleOpenAD = () => {
  //   setOpenAD(true);
  // };
  return (
    <>
      <Container sx={{ alignItems: 'center', mt: 1 }}>
        <Helmet>
          <title>H.I.S | Login</title>
        </Helmet>

        <Stack spacing={1}>
          <Card sx={{ p: 2 }}>
            <Typography variant="h6">
              1. After Creating the account go to the link mentioned in step 1 and login the
              account.
            </Typography>

            <Box sx={{ p: 2 }}>
              <Link
                href="https://his.8nergyit.com/auth/login"
                target="_blank"
                rel="noopener"
                underline="none"
                sx={{ color: '#000000' }}
              >
                <Button variant="contained" color="primary">
                  https://his.8nergyit.com/auth/login
                </Button>
              </Link>
            </Box>
          </Card>
          <Card sx={{ p: 2 }}>
            <Typography variant="h6">
              2. This page should appear after click the site.
              <Stack sx={{ p: 2, alignItems: 'center' }}>
                <Image src="/assets/images/HIS/Login/LoginPage.PNG" width={800} />
              </Stack>
            </Typography>
          </Card>
          <Card sx={{ p: 2 }}>
            <Typography variant="h6">
              3. Click the
              <Button variant="contained" sx={{ mx: 1, bgcolor: '#00AB55', fontWeight: 'bolder' }}>
                Login
              </Button>
              Button
            </Typography>
          </Card>
        </Stack>
      </Container>
    </>
  );
}

export default ViewLogin;

import React from 'react';
// import { useTheme } from '@mui/material/styles';
// import { color } from '@mui/system';

import {
  Box,
  Divider,
  Typography,
  Grid,
  Stack,
  // Button,
  Avatar,
  // TextField,
  // FormControlLabel,
  // Radio,
  // InputAdornment,
} from '@mui/material';
// COMPONENTS
import Label from '../../../../components/label/Label';
import Scrollbar from '../../../../components/scrollbar/Scrollbar';

function Preview() {
  return (
    <>
      <Grid container spacing={1} sx={{ mb: 2, alignItems: 'center' }}>
        <Grid item md={6}>
          <Typography variant="h5">Triage Record</Typography>
        </Grid>
        <Grid
          item
          md={6}
          sx={{ display: 'flex', justifyContent: 'flex-end', justifyItems: 'flex-end' }}
        >
          {/* {data.patient_type} */}
          <Label
            sx={{
              textTransform: 'capitalize',
              bgcolor: 'white',
              //   color: theme.palette.info.dark,
              color: '#0C53B7',
              width: 105,
              borderRadius: '20px',
              border: '2px solid',
              py: 1,
            }}
          >
            IN-PATIENT
          </Label>
        </Grid>
      </Grid>
      <Divider />
      <Scrollbar sx={{ maxHeight: 500 }}>
        <Grid item md={12}>
          <Grid item md={12} sx={{ mt: 2 }}>
            <Box sx={{ p: 2, bgcolor: 'rgba(211, 211, 211, 0.15)', borderRadius: '12px' }}>
              <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                {/* START OF 1ST COLUMN */}
                <Avatar sx={{ width: '5rem', height: '5rem' }}>FE</Avatar>
                <Grid container spacing={1}>
                  <Grid item md={12}>
                    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                      <Typography variant="body1">Patient Name:</Typography>
                      <Typography variant="h5" sx={{ fontWeight: 'bolder' }}>
                        {/* {data.patients?.full_name} */}Isaac Doe
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item md={12}>
                    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                      <Typography variant="body1">Address:</Typography>

                      <Typography variant="h6" noWrap sx={{ fontWeight: 'bolder' }}>
                        ---------------------------------------------------
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item md={2}>
                    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                      <Typography variant="body1">Age:</Typography>

                      <Typography variant="h6" sx={{ fontWeight: 'bolder' }}>
                        -----
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item md={2}>
                    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                      <Typography variant="body1">Sex:</Typography>

                      <Typography variant="h6" sx={{ fontWeight: 'bolder' }}>
                        -------
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item md={3}>
                    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                      <Typography variant="body1">Civil Status:</Typography>

                      <Typography variant="h6" sx={{ fontWeight: 'bolder' }}>
                        ----------
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item md={5}>
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ alignItems: 'center' }}
                      justifyContent="center"
                    >
                      <Typography variant="body1">Date of Birth:</Typography>

                      <Typography variant="h6" sx={{ fontWeight: 'bolder' }}>
                        -------------------{' '}
                        {/* {new Date(data.patients?.birth_date).toLocaleString('default', {
                          month: 'short',
                          day: 'numeric',
                          year: 'numeric',
                        })} */}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            </Box>
            {/* END OF 1st COLUMN  */}
            {/* START OF 2nd COLUMN  */}

            <Stack spacing={0} sx={{ mt: 0 }}>
              <Stack
                direction="row"
                spacing={1}
                justifyContent="space-between"
                sx={{ alignItems: 'center', mt: 4 }}
              >
                <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                  <Typography variant="body2">Referred By:</Typography>

                  <Typography variant="h6" sx={{ fontWeight: 'bolder' }}>
                    PRIVATE-VEHICLE{' '}
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                justifyContent="space-between"
                sx={{ alignItems: 'center', mt: 4 }}
              >
                <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                  <Typography variant="body2">Smoking :</Typography>

                  <Typography variant="h6" sx={{ fontWeight: 'bolder' }}>
                    Yes{' '}
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                justifyContent="space-between"
                sx={{ alignItems: 'center', mt: 4 }}
              >
                <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                  <Typography variant="body2">Vital Signs:</Typography>
                </Stack>
              </Stack>
              {/* Vital Stats */}
              <Grid container spacing={1} sx={{ mt: 0.5 }}>
                <Grid item md={12}>
                  <Box sx={{ p: 2, bgcolor: 'rgba(211, 211, 211, 0.15)', borderRadius: '12px' }}>
                    <Stack>
                      <Stack direction="row" spacing={1} sx={{ alignItems: 'center', mb: 1 }}>
                        <Grid container spacing={1}>
                          <Grid item md={4}>
                            <Typography variant="caption">Blood Pressure:</Typography>
                          </Grid>
                          <Grid
                            item
                            md={8}
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              justifyItems: 'flex-end',
                            }}
                          >
                            <Typography variant="h6" fontWeight="bolder">
                              131 mmHg
                            </Typography>
                          </Grid>
                        </Grid>
                      </Stack>
                      <Divider />

                      <Stack direction="row" spacing={1} sx={{ alignItems: 'center', mb: 1 }}>
                        <Grid container spacing={1}>
                          <Grid item md={4}>
                            <Typography variant="caption">Heart Pressure:</Typography>
                          </Grid>
                          <Grid
                            item
                            md={8}
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              justifyItems: 'flex-end',
                            }}
                          >
                            <Typography variant="h6" fontWeight="bolder">
                              231 bpm
                            </Typography>
                          </Grid>
                        </Grid>
                      </Stack>
                      <Divider />

                      <Stack direction="row" spacing={1} sx={{ alignItems: 'center', mb: 1 }}>
                        <Grid container spacing={1}>
                          <Grid item md={4}>
                            <Typography variant="caption">Respiration Rate:</Typography>
                          </Grid>
                          <Grid
                            item
                            md={8}
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              justifyItems: 'flex-end',
                            }}
                          >
                            <Typography variant="h6" fontWeight="bolder">
                              21 bpm
                            </Typography>
                          </Grid>
                        </Grid>
                      </Stack>
                      <Divider />

                      <Stack direction="row" spacing={1} sx={{ alignItems: 'center', mb: 1 }}>
                        <Grid container spacing={1}>
                          <Grid item md={4}>
                            <Typography variant="caption">Temperature:</Typography>
                          </Grid>
                          <Grid
                            item
                            md={8}
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              justifyItems: 'flex-end',
                            }}
                          >
                            <Typography variant="h6" fontWeight="bolder">
                              213 °C
                            </Typography>
                          </Grid>
                        </Grid>
                      </Stack>
                      <Divider />

                      <Stack direction="row" spacing={1} sx={{ alignItems: 'center', mb: 1 }}>
                        <Grid container spacing={1}>
                          <Grid item md={4}>
                            <Typography variant="caption">Weigh:</Typography>
                          </Grid>
                          <Grid
                            item
                            md={8}
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              justifyItems: 'flex-end',
                            }}
                          >
                            <Typography variant="h6" fontWeight="bolder">
                              213 kgs
                            </Typography>
                          </Grid>
                        </Grid>
                      </Stack>
                      <Divider />

                      <Stack direction="row" spacing={1} sx={{ alignItems: 'center', mb: 1 }}>
                        <Grid container spacing={1}>
                          <Grid item md={4}>
                            <Typography variant="caption">Height:</Typography>
                          </Grid>
                          <Grid
                            item
                            md={8}
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              justifyItems: 'flex-end',
                            }}
                          >
                            <Typography variant="h6" fontWeight="bolder">
                              213 cm
                            </Typography>
                          </Grid>
                        </Grid>
                      </Stack>
                      <Divider />

                      <Stack direction="row" spacing={1} sx={{ alignItems: 'center', mb: 1 }}>
                        <Grid container spacing={1}>
                          <Grid item md={4}>
                            <Typography variant="caption">OT Saturation:</Typography>
                          </Grid>
                          <Grid
                            item
                            md={8}
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              justifyItems: 'flex-end',
                            }}
                          >
                            <Typography variant="h6" fontWeight="bolder">
                              213 %
                            </Typography>
                          </Grid>
                        </Grid>
                      </Stack>
                      <Divider />
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
              {/* end stack */}
            </Stack>
            {/* END OF 2nd COLUMN */}
            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-between"
              sx={{ alignItems: 'center', mt: 4 }}
            >
              <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                <Typography variant="body2">Chief Complaints:</Typography>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-between"
              sx={{ alignItems: 'center', mt: 0.5 }}
            >
              <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                <Typography variant="h6" fontWeight="bolder">
                  test answer data{' '}
                </Typography>
              </Stack>
            </Stack>
            {/* END OF 3RD COLUMN */}
            {/* START OF 4TH COLUMN */}
            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-between"
              sx={{ alignItems: 'center', mt: 4 }}
            >
              <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                <Typography variant="body2">Past Medical:</Typography>
              </Stack>
            </Stack>
            {/* END OF 4TH COLUMN */}
            {/* START OF 5HT COLUMN */}
            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-between"
              sx={{ alignItems: 'center', mt: 0.5 }}
            >
              <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                <Typography variant="h6" sx={{ color: 'red' }}>
                  None
                </Typography>
                <Typography variant="h6">Diabetes</Typography>
                <Typography variant="h6">Astma</Typography>
                <Typography variant="h6">ICU</Typography>
                <Typography variant="h6">PTB</Typography>
                <Typography variant="h6">Hyper Tension</Typography>
                <Typography variant="h6">Cardiac</Typography>
              </Stack>
            </Stack>
            {/* END OF 5HT COLUMN */}
            {/* STAR OF 6TH COLUMN */}
            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-between"
              sx={{ alignItems: 'center', mt: 4 }}
            >
              <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                <Typography variant="body2">Medications:</Typography>
              </Stack>
            </Stack>

            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-between"
              sx={{ alignItems: 'center', mt: 0.5 }}
            >
              <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                <Typography variant="h6">SAMPLE medications </Typography>
                <Typography variant="h6">SAMPLE medications </Typography>

                <Typography variant="h6">SAMPLE medications </Typography>

                <Typography variant="h6">SAMPLE medications </Typography>

                <Typography variant="h6">SAMPLE medications </Typography>

                <Typography variant="h6">SAMPLE medications </Typography>
                <Typography variant="h6">SAMPLE medications </Typography>
              </Stack>
            </Stack>
            {/* END OF 6TH COLUMN */}
            {/* START 7TH COLUMN */}

            <Grid item md={12} sx={{ mt: 4 }}>
              <Grid container spacing={1} sx={{ p: 1 }}>
                <Grid item md={4} sx={{ border: '1px solid gainsboro', p: 2, ml: 0.5 }}>
                  <Typography variant="subtitle1" sx={{ color: '#696969' }}>
                    Triage Officer:
                  </Typography>
                  <Typography variant="h5" sx={{ textAlign: 'center', mt: 1 }}>
                    -----
                  </Typography>
                </Grid>
                <Grid item md={4} sx={{ border: '1px solid gainsboro', p: 2 }}>
                  <Typography variant="subtitle1" sx={{ color: '#696969' }}>
                    Date:
                  </Typography>
                  <Typography variant="h5" sx={{ textAlign: 'center', mt: 1, color: '#483D8B' }}>
                    Jan 20, 2024
                  </Typography>
                </Grid>

                <Grid item md={4} sx={{ border: '1px solid gainsboro', p: 2, mr: -1 }}>
                  <Typography variant="subtitle1" sx={{ color: '#696969' }}>
                    Time:
                  </Typography>
                  <Typography variant="h5" sx={{ textAlign: 'center', mt: 1 }}>
                    12:55pm{' '}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {/* END OF 7TH COLUMN */}
            {/* end of grid */}
          </Grid>
        </Grid>
      </Scrollbar>
    </>
  );
}

export default Preview;

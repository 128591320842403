import PropTypes from 'prop-types';
// @mui
import { Dialog, Button, DialogTitle, DialogActions, DialogContent, } from '@mui/material';
import Scrollbar from '../scrollbar/Scrollbar';

// ----------------------------------------------------------------------

ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.node,
  action: PropTypes.node,
  content: PropTypes.node,
  onClose: PropTypes.func,
};

export default function ConfirmDialog({ title, content, action, open, onClose, ...other }) {
  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose} {...other}>
      <Scrollbar sx={{ maxHeight: '40rem', p: 3 }}>
        <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>

        {content && <DialogContent sx={{ typography: 'body2' }}> {content} </DialogContent>}

        <DialogActions>
          {action}

          <Button variant="outlined" color="inherit" onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </Scrollbar>
    </Dialog>
  );
}

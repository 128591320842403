import _mock from '../_mock';
import { randomNumberRange, randomInArray } from '../utils';

// ----------------------------------------------------------------------

export const _appRelated = [
  'Biogesic Paracetamol',
  'Losartan Premium',
  'Ascof Lagundi Tablets',
  'Bioflu',
  'Basic Paracetamol',
].map((name, index) => ({
  id: _mock.id(index),
  name,
  system: (index === 2 && 'Windows') || (index === 4 && 'Windows') || 'Mac',
  price: index === 0 || index === 2 || index === 4 ? 0 : _mock.number.price(index),
  rating: _mock.number.rating(index),
  review: randomNumberRange(1, 499),

  nameplace: ['South Poblacion', 'Tugos', 'Bagong Silang III', 'San Lorenzo', 'Lag-On'][index],
  municipality: ['Jose Panganiban', 'Paracale', 'Labo', 'Santa Elena', 'Daet'][index],
  // price: index === 0 || index === 2 || index === 4 ? 0 : _mock.number.price(index),
  // rating: _mock.number.rating(index),
  // review: randomNumberRange(1, 999),
  med: ['Biogesic Paracetamol', 'Losartan Premium', 'Ascof Lagundi Tablets', 'Bioflu', 'Basic Paracetamol'][index],
  review2: randomNumberRange(999, 9999),
  hospital: ['Jose Panganiban Provincial Hospital', 'Community Hospital of Paracale', 'Labo Multispecialty Clinic', 'Santa Elena Hospital', 'Camarines Norte Provincial Hospital'][index],
  // shortcut: `https://minimal-assets-api-dev.vercel.app/assets/icons/ic_${noCase(appName)}.svg`,




  shortcut:
    (name === 'Chrome' && '/assets/icons/apps/ic_chrome.svg') ||
    (name === 'Drive' && '/assets/icons/apps/ic_drive.svg') ||
    (name === 'Dropbox' && '/assets/icons/apps/ic_dropbox.svg') ||
    (name === 'Evernote' && '/assets/icons/apps/ic_evernote.svg') ||
    '/assets/icons/apps/ic_github.svg',

}));

// ----------------------------------------------------------------------

export const _appInstalled = ['de', 'en', 'fr', 'kr', 'us'].map((country, index) => ({
  id: _mock.id(index),
  name: ['Germany', 'England', 'France', 'Korean', 'USA'][index],
  android: randomNumberRange(999, 99999),
  windows: randomNumberRange(999, 99999),
  apple: randomNumberRange(999, 99999),
  flag: `/assets/icons/flags/ic_flag_${country}.svg`,
}));

// ----------------------------------------------------------------------

export const _appAuthors = [...Array(3)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.name.fullName(index),
  avatar: _mock.image.avatar(index),
  favourite: randomNumberRange(9999, 19999),
}));

// ----------------------------------------------------------------------

export const _appInvoices = [...Array(5)].map((_, index) => ({
  id: `${Date.now() + index}`,
  price: _mock.number.price(index),
  category: randomInArray(['Android', 'Mac', 'Windows']),
  status: randomInArray(['paid', 'out_of_date', 'in_progress']),
}));

// ----------------------------------------------------------------------

export const _appFeatured = [...Array(3)].map((_, index) => ({
  id: _mock.id(index),
  title: [
    'Harry Potter and the Deathly Hallows - Part 2',
    'Disney Zombies 2',
    'Lightroom mobile - Koloro',
  ][index],
  description: _mock.text.title(index),
  image: _mock.image.cover(index),
}));

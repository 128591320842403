import React from 'react';

import PropTypes from 'prop-types';

import {
  Grid,
  Typography,
  Stack,
  Button,
  TextField,
  Switch,
  Select,
  MenuItem,
  InputLabel,
  Divider,
  Chip,
} from '@mui/material';
import { Image } from 'mui-image';
// COMPONENTS
// import DateRangePicker, { useDateRangePicker } from '../../../../components/date-range-picker';
import Scrollbar from '../../../../components/scrollbar/Scrollbar';

// AddUser.propTypes = {
//   handleDrop: PropTypes.func,
// };

AddUser.propTypes = {
  variant: PropTypes.string,
  onFilterService: PropTypes.func,
  onFilterStartDate: PropTypes.func,
  filterStartDate: PropTypes.instanceOf(Date),
};

export default function AddUser({
  variant,
  // isFiltered,
  // filterName,
  // onFilterName,
  // filterEndDate,
  // filterService,
  // onResetFilter,
  // optionsService,
  filterStartDate,
  onFilterService,
  // onFilterEndDate,
  onFilterStartDate,
}) {

  return (
    <>
      {/* <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}> */}
      <Scrollbar sx={{ maxHeight: 500 }}>
        <Typography variant="h6">
          <Stack sx={{ p: 2, alignItems: 'center' }}>
            <Button>
              <Image src="/assets/images/HIS/Signup/Upload.PNG" height={400} width={500} />
            </Button>
            <Grid item xs={12} sm={12} md={12}>
              <Divider fullWidth>
                <Chip label="User Account" sx={{ mb: 2 }} />
              </Divider>
            </Grid>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              {/* START */}
              <Grid item xs={12} sm={4} md={6}>
                <TextField variant={variant} required fullWidth label="Username " />
              </Grid>
              <Grid item xs={12} sm={4} md={6}>
                <TextField variant={variant} required fullWidth label="Password" />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <InputLabel>Type</InputLabel>

                <Select variant={variant} fullWidth label="Type">
                  <MenuItem key="nurse" value="Nurse">
                    Nurse
                  </MenuItem>
                  <MenuItem key="doctor" value="Doctor" disabled>
                    Doctor (Not Available)
                  </MenuItem>
                  <MenuItem key="patient" value="Patient" disabled>
                    Patient (Not Available)
                  </MenuItem>
                  <MenuItem key="patient" value="Staff">
                    Staff
                  </MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <InputLabel>Role</InputLabel>

                <Select variant={variant} fullWidth label="Type">
                  <MenuItem value="Nurse">Govs App</MenuItem>
                  <MenuItem value="Doctor" disabled>
                    Dynamic Role
                  </MenuItem>
                  <MenuItem value="Patient" disabled>
                    Admin
                  </MenuItem>
                  <MenuItem value="Staff">Staff</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <InputLabel>Position</InputLabel>

                <Select variant={variant} fullWidth label="Type">
                  <MenuItem value="CONSULTANTS">CONSULTANTS </MenuItem>
                  <MenuItem value="OBSTETRICS/ GYNECOLOGY">OBSTETRICS/ GYNECOLOGY</MenuItem>
                  <MenuItem value="PEDIATRICS">PEDIATRICS</MenuItem>
                  <MenuItem value="SURGERY">SURGERY</MenuItem>
                  <MenuItem value="ANESTHESIOLOGIST">ANESTHESIOLOGIST</MenuItem>

                  <MenuItem value="RADIOLOGIST">RADIOLOGIST</MenuItem>

                  <MenuItem value="PATHOLOGIST">PATHOLOGIST</MenuItem>

                  <MenuItem value="POST-GRADUATE FELLOWS">POST-GRADUATE FELLOWS</MenuItem>

                  <MenuItem value="RESIDENTS">RESIDENTS</MenuItem>
                  <MenuItem value="INTERNAL MEDICINE">INTERNAL MEDICINE</MenuItem>
                  <MenuItem value="OBSTETRICTS-GYNECOLOGY">OBSTETRICTS-GYNECOLOGY</MenuItem>
                  <MenuItem value="INTERNAL MEDICINE">INTERNAL MEDICINE</MenuItem>
                  <MenuItem value="PEDIATRICS">PEDIATRICS</MenuItem>

                  <MenuItem value="SURGERY">SURGERY</MenuItem>

                  <MenuItem value="NURSES">NURSES</MenuItem>
                  <MenuItem value="MIDWIVES">MIDWIVES</MenuItem>
                  <MenuItem value="NURSING AIDES">NURSING AIDES</MenuItem>
                  <MenuItem value="NUTRITIONIST">NUTRITIONIST</MenuItem>
                  <MenuItem value="PHYSICAL THERAPIST">PHYSICAL THERAPIST</MenuItem>
                  <MenuItem value="PHARMACISTS">PHARMACISTS</MenuItem>
                  <MenuItem value="MEDICAL TECHNOLOGIST">MEDICAL TECHNOLOGIST</MenuItem>
                  <MenuItem value="GENERALIST">GENERALIST</MenuItem>
                  <MenuItem value="LABORATORY TECHNICIAN">LABORATORY TECHNICIAN</MenuItem>
                  <MenuItem value="X-RAY TECHNOLOGIST/X-RAY TECHNICIAN">
                    X-RAY TECHNOLOGIST/X-RAY TECHNICIAN
                  </MenuItem>
                  <MenuItem value="MEDICAL EQUIPMENT TECHNICIAN">
                    MEDICAL EQUIPMENT TECHNICIAN
                  </MenuItem>
                  <MenuItem value="SOCIAL WORKER">SOCIAL WORKER</MenuItem>
                  <MenuItem value="MEDICAL RECORDS OFFICER/ HOSPITAL HEALTH INFORMATION OFFICER">
                    MEDICAL RECORDS OFFICER/ HOSPITAL HEALTH INFORMATION OFFICER
                  </MenuItem>
                  <MenuItem value="CHIEF ADMINISTRATIVE OFFICER">
                    CHIEF ADMINISTRATIVE OFFICER
                  </MenuItem>
                  <MenuItem value="ACCOUNTANT">ACCOUNTANT</MenuItem>
                  <MenuItem value="BUDGET OFFICER">BUDGET OFFICER</MenuItem>
                  <MenuItem value="CASHIER">CASHIER</MenuItem>
                  <MenuItem value="CLERK">CLERK</MenuItem>
                  <MenuItem value="CARDIOLOGIST">CARDIOLOGIST</MenuItem>
                  <MenuItem value="ENGINEER">ENGINEER</MenuItem>
                  <MenuItem value="DRIVER">DRIVER</MenuItem>
                  <MenuItem value="GENERAL SUPPORT STAFF">GENERAL SUPPORT STAFF</MenuItem>
                  <MenuItem value="JANITORIAL">JANITORIAL</MenuItem>
                  <MenuItem value="MAINTENANCE">MAINTENANCE</MenuItem>
                  <MenuItem value="SECURITY">SECURITY</MenuItem>
                  <MenuItem value="ENDOCRINOLOGIST">ENDOCRINOLOGIST</MenuItem>
                  <MenuItem value="GASTRO-ENTEROLOGIST">GASTRO-ENTEROLOGIST</MenuItem>
                  <MenuItem value="PULMONOLOGIST">PULMONOLOGIST</MenuItem>
                  <MenuItem value="NEPHROLOGIST">NEPHROLOGIST</MenuItem>
                  <MenuItem value="NEUROLOGIST">NEUROLOGIST</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Switch>dot</Switch>
                <Typography variant="h6">Active Staff Rotating</Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Switch>dot</Switch>
                <Typography variant="h6"> Full Time Staff </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Switch>dot</Switch>
                <Typography variant="h6"> Part Time Staff </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Switch>dot</Switch>
                <Typography variant="h6"> Is Outsource </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Divider fullWidth>
                  <Chip label="User Information" />
                </Divider>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField variant={variant} fullWidth label="First Name " />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField variant={variant} fullWidth label="Middle Name " />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField variant={variant} fullWidth label="Last Name " />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField variant={variant} fullWidth label="Birthdate " />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField variant={variant} fullWidth label="Phone Number " />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField variant={variant} fullWidth label="Email " />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField variant={variant} fullWidth label="Nationality " />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField variant={variant} fullWidth label="Gender " />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField variant={variant} fullWidth label="Salary " />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <InputLabel>Civil Status</InputLabel>

                <Select fullWidth label="Type">
                  <MenuItem value="Windowed">Windowed</MenuItem>
                  <MenuItem value="Single">Single </MenuItem>
                  <MenuItem value="Married">Married </MenuItem>
                  <MenuItem value="Divorce">Divorce</MenuItem>
                  <MenuItem value="Separated">Separated</MenuItem>
                </Select>
              </Grid>
              {/* END */}
            </Grid>
          </Stack>
        </Typography>
      </Scrollbar>
      {/* </FormProvider> */}
    </>
  );
}

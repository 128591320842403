import React, { useState } from 'react';
import {
  Card,
  Typography,
  Stack,
  Container,
  // Box,
  Button,
  IconButton,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
// import { Image } from 'mui-image';
import Iconify from '../../../components/iconify/Iconify';
import ConfirmDialog from '../../../components/confirm-dialog/ConfirmDialog';
import Triage from './Components/Triage';
import TriageTableToolbar from './Components/TriageToolBar';
import Preview from './Components/Preview';
import Edit from './Components/Edit';
import Archive from './Components/Archive';
import Add from './Components/Add';

// import AddUser from './Components/AddUser';

function ViewTriage() {
  // HOOKS
  // VIEW TRIAGE
  const [openAD, setOpenAD] = useState(false);

  const handleCloseAD = () => {
    setOpenAD(false);
  };
  const handleOpenAD = () => {
    setOpenAD(true);
  };
  // EDIT TRIAGE
  const [openEDIT, setOpenEDIT] = useState(false);

  const handleOpenEDIT = () => {
    setOpenEDIT(true);
  };
  const handleCloseEDIT = () => {
    setOpenEDIT(false);
  };
  // ARCHIVE TRIAGE
  const [openARCHIVE, setOpenARCHIVE] = useState(false);

  const handleOpenARCHIVE = () => {
    setOpenARCHIVE(true);
  };
  const handleCloseARCHIVE = () => {
    setOpenARCHIVE(false);
  };
  // ARCHIVE TRIAGE
  const [openADDTR, setOpenADDTR] = useState(false);

  const handleOpenADDTR = () => {
    setOpenADDTR(true);
  };
  const handleCloseADDTR = () => {
    setOpenADDTR(false);
  };
  return (
    <>
      <Container sx={{ alignItems: 'center', mt: 1 }}>
        <Helmet>
          <title>H.I.S | Triage</title>
        </Helmet>
        <Stack spacing={1}>
          <Card sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ pb: 2 }}>
              1. Below is the Triage Table Data you can view upon logging in
              <Card>
                <Triage />
              </Card>
            </Typography>
          </Card>
          <Card sx={{ p: 2 }}>
            <Typography variant="h6">
              2. You can Search the Patient or the Patient Number
              {/* <Stack sx={{ p: 2, alignItems: 'center' }}> */}
              <TriageTableToolbar />
              {/* </Stack> */}
            </Typography>
          </Card>
          <Card sx={{ p: 2 }}>
            <Typography variant="h6">
              3. Click the
              <Button
                variant="contained"
                onClick={handleOpenADDTR}
                sx={{ mx: 1, bgcolor: '#00AB55', fontWeight: 'bolder' }}
              >
                Add Triage Record
              </Button>
              To Add Triage Record
            </Typography>
          </Card>
          <Card sx={{ p: 2 }}>
            <Typography variant="h6">
              4. Click the
              <Button
                variant="contained"
                onClick={handleOpenAD}
                sx={{ mx: 1, bgcolor: '#00AB55', fontWeight: 'bolder' }}
              >
                Preview
              </Button>
              To View Triage Record
            </Typography>
          </Card>
          <Card sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ mt: 1 }}>
              5. Click the
              <Button
                // variant="contained"
                onClick={handleOpenEDIT}
                sx={{
                  backgroundColor: 'rgba(34, 139, 34, 0.2)',
                  '&:hover': { backgroundColor: 'rgba(34, 139, 34, 0.2)' },
                  mx: 1,
                }}
              >
                <IconButton>
                  <Iconify
                    icon="mdi:clipboard-edit-outline"
                    width={22}
                    height={22}
                    // sx={is_status !== 'Archived' ? { color: '#228B22' } : { color: 'gray' }}
                  />
                </IconButton>
              </Button>
              To View Edit Triage Record
            </Typography>
          </Card>
          <Card sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ mt: 1 }}>
              6. Click the
              <Button
                // variant="contained"
                onClick={handleOpenARCHIVE}
                sx={{
                  backgroundColor: '#B2222233',
                  '&:hover': { backgroundColor: '#B2222233' },
                  mx: 1,
                }}
              >
                <IconButton>
                  <Iconify
                    icon="iconoir:cancel"
                    width={22}
                    height={22}
                    // sx={is_status !== 'Archived' ? { color: '#228B22' } : { color: 'gray' }}
                  />
                </IconButton>
              </Button>
              To View Archive Triage Record
            </Typography>
          </Card>
        </Stack>
      </Container>

      {/* Add Triage Record Confirmation Dialog */}
      <ConfirmDialog
        open={openADDTR}
        onClose={handleCloseADDTR}
        content={
          <Add
          // handleClose={handleCloseAD}
          // fetchTable={fetchTable}
          // handleChipValue={handleChipValue}
          />
        }
      />
      {/* Preview Triage Record Confirmation Dialog */}
      <ConfirmDialog
        open={openAD}
        onClose={handleCloseAD}
        content={
          <Preview
          // handleClose={handleCloseAD}
          // fetchTable={fetchTable}
          // handleChipValue={handleChipValue}
          />
        }
      />
      {/* Edit Triage Record Confirmation Dialog */}
      <ConfirmDialog
        open={openEDIT}
        onClose={handleCloseEDIT}
        content={
          <Edit
          // handleClose={handleCloseEDIT}
          // fetchTable={fetchTable}
          // handleChipValue={handleChipValue}
          />
        }
      />
      {/* Archive Triage Record Confirmation Dialog */}
      <ConfirmDialog
        open={openARCHIVE}
        onClose={handleCloseARCHIVE}
        content={
          <Archive
          // handleClose={handleCloseEDIT}
          // fetchTable={fetchTable}
          // handleChipValue={handleChipValue}
          />
        }
      />
    </>
  );
}

export default ViewTriage;

import { useState, useEffect } from 'react';
import {
  Box,
  Checkbox,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  Table,
  TableContainer,
  Button,
  IconButton,
  Tabs,
  Tab,
} from '@mui/material';
import Image from 'mui-image';
import { Stack, color, width } from '@mui/system';

import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableSkeleton,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from '../../../../components/table';
import Iconify from '../../../../components/iconify/Iconify';
import { _inpatientSample } from '../../../../_mock/arrays';
import EmptyContent from '../../../../components/empty-content/EmptyContent';
import Avatar from '../../../../theme/overrides/Avatar';
import Typography from '../../../../theme/overrides/Typography';
import Label from '../../../../components/label/Label';
import TriageTableToolbar from './TriageToolBar';

const TABLE_HEAD = [
  { id: '', label: 'Patient Details', align: 'left' },
  { id: '', label: 'Patient Type', align: 'center' },
  { id: '', label: 'Priority', align: 'center' },
  { id: '', label: 'Admission Date', align: 'center' },
  { id: '', label: 'Status', align: 'center' },
  { id: '', label: 'Triage Form', align: 'center' },
  { id: '', label: '', align: 'center' },
];

const TABLE_HEAD_ARCHIVE = [
  { id: '', label: '', align: 'center' },

  { id: '', label: 'Patient Details', align: 'left' },
  { id: '', label: 'Patient Type', align: 'center' },
  { id: '', label: 'Priority', align: 'center' },
  { id: '', label: 'Admission Date', align: 'center' },
  { id: '', label: 'Status', align: 'center' },
  { id: '', label: 'Triage Form', align: 'center' },
  { id: '', label: '', align: 'center' },
];
const TABS = [
  { value: 'all', label: 'All' },
  { value: 'pending', label: 'Pending' },
  { value: 'accepted', label: 'Accepted' },
  { value: 'discharge', label: 'Discharge' },
  { value: 'archived', label: 'Archived' },
];
function Triage() {
  const [tableData, setTableData] = useState(_inpatientSample);

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,

    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: 'createDate' });
  return (
    <div>
      <Tabs
        allowScrollButtonsMobile
        variant="scrollable"
        scrollButtons="auto"
        // value={tabStatus}
        // onChange={onTabStatus}
        sx={{ px: 1, bgcolor: '#63738133' }}
      >
        {TABS.map((tab) => (
          <Tab
            sx={{ fontWeight: 'bolder' }}
            disableRipple
            key={tab.value}
            value={tab.value}
            label={tab.label}
          />
        ))}
      </Tabs>
      <TriageTableToolbar />
      <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
        <Table size={dense ? 'medium' : 'large'}>
          <TableHeadCustom
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            rowCount={tableData.length}
            numSelected={selected.length}
            onSort={onSort}
            onSelectAllRows={(checked) =>
              onSelectAllRows(
                checked,
                tableData.map((row) => row.id)
              )
            }
          />
          {/* <TableCell sx={{ display: 'flex', alignItems: 'center' }}> */}
          <Stack direction="row">
            <Box sx={{ pt: 2.5 }}>
              <Image src="/assets/images/HIS/camnorte_seal.png" height={40} width={40} />
            </Box>
            <TableCell sx={{ display: 'flex', border: '#ffffff', mt: 2 }}>
              {/* {lastName} */}
              John Doe {/* #010101 */}
              {/* </TableCell>
            <TableCell sx={{ border: '#ffffff' }}> */}
              {/* {lastName} */}
              #000111 {/* #010101 */}
            </TableCell>
          </Stack>

          <TableCell sx={{ border: '#ffffff' }}>
            <Label
              sx={{
                textTransform: 'capitalize',
                bgcolor: 'white',
                color: '#0C53B7',
                // color: theme.palette.info.dark,
                width: 105,
                borderRadius: '20px',
                border: '2px solid',
                py: 1,
              }}
            >
              IN-PATIENT
            </Label>
          </TableCell>
          <TableCell sx={{ display: 'flex', border: '#ffffff', alignContent: 'center' }}>
            <b>Senior Citizen / Pregnant </b>
          </TableCell>
          <TableCell sx={{ border: '#ffffff' }}>May 8, 2024, 10:39 PM</TableCell>
          <TableCell sx={{ border: '#ffffff' }}>
            <Label
              sx={{
                textTransform: 'capitalize',
                bgcolor: 'white',
                color: '#0C53B7',
                // color: theme.palette.info.dark,
                width: 105,
                borderRadius: '20px',
                border: '2px solid',
                py: 1,
              }}
            >
              ACCEPTED
            </Label>
          </TableCell>
          <TableCell align="center" sx={{ border: '#ffffff' }}>
            <Button
              variant="contained"
              size="small"
              sx={{ fontWeight: 'bolder', bgcolor: '#00AB55' }}
            >
              Preview
            </Button>
          </TableCell>
          <TableCell align="center" sx={{ border: '#ffffff' }}>
            <Stack direction="row" justifyContent="space-between">
              <IconButton
                sx={{
                  backgroundColor: 'rgba(34, 139, 34, 0.2)',
                  '&:hover': { backgroundColor: 'rgba(34, 139, 34, 0.2)' },
                }}
              >
                <Iconify
                  icon="mdi:clipboard-edit-outline"
                  width={22}
                  height={22}
                  // sx={is_status !== 'Archived' ? { color: '#228B22' } : { color: 'gray' }}
                />
              </IconButton>
              <IconButton
                sx={{
                  backgroundColor: 'rgba(178, 34, 34, 0.2)',
                  '&:hover': { backgroundColor: 'rgba(178, 34, 34, 0.2)' },
                }}
              >
                <Iconify
                  icon="iconoir:cancel"
                  width={22}
                  height={22}
                  // sx={is_status !== 'Archived' ? { color: '#B22222' } : { color: 'gray' }}
                />
              </IconButton>
            </Stack>
          </TableCell>

          {/* </TableCell> */}
        </Table>
      </TableContainer>
    </div>
  );
}

export default Triage;

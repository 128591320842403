import React from 'react';

import { Stack } from '@mui/material';

import { Image } from 'mui-image';

function Header() {
  return (
    <div>
      <Stack direction="row" justifyContent="center" alignItems="center">
        <Image src="/assets/images/HIS/camnorte_seal.png" height={100} width={100} />
      </Stack>
    </div>
  );
}

export default Header;

// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/label';
// import Iconify from '../../../components/iconify';
import SvgColor from '../../../components/svg-color';
import Iconify from '../../../components/iconify/Iconify';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);
const getIconify = (name) => <Iconify icon={`${name}`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  // HIS---------------------------------------------------------------
  login: getIconify('ic:baseline-login'),
  triage: getIconify('fluent:tent-12-regular'),
  usermanagent: getIconify('ic:outline-supervised-user-circle'),
  chart: getIconify('icon-park-outline:schedule'),
  queue: getIconify('fluent-mdl2:people-repeat'),
  wards: getIconify('ic:round-bed'),
  clinic: getIconify('material-symbols:medical-services-outline-rounded'),
  laboratory: getIconify('mdi:flask-outline'),
  emergency: getIconify('ic:outline-emergency'),
  inpatients: getIconify('material-symbols:inpatient-outline'),
  outpatient: getIconify('material-symbols:outpatient-outline-rounded'),
  billing: getIconify('icon-park-outline:bill'),
  pharmacy: getIconify('ic:outline-local-pharmacy'),
  medicalrec: getIconify('mdi:clipboard-account-outline'),
  govview: getIconify('mdi:person-tie'),
  patient: getIconify('akar-icons:people-group'),
  doctor: getIconify('wpf:medical-doctor'),
  dohreport: getIconify('tabler:report'),
  historylogs: getIconify('icon-park-outline:upload-logs'),
  filemanager: getIconify('ph:folder-bold'),
  patientsurvey: getIconify('ri:survey-line'),
  expense: getIconify('carbon:money'),
  settings: getIconify('gala:settings'),
  ward: getIconify('material-symbols:ward-outline'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Hospital Information System',
    items: [
      // { title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      // { title: 'ecommerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
      // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
      // { title: 'file', path: PATH_DASHBOARD.general.file, icon: ICONS.file },
      // ---------------------------------------------------------------------------

      // H.I.S

      {
        title: 'Singup',
        path: PATH_DASHBOARD.ViewSignUp,
        icon: ICONS.usermanagent,
      },
      {
        title: 'Login',
        path: PATH_DASHBOARD.ViewLogin,
        icon: ICONS.login,
      },
      {
        title: 'Triage',
        path: PATH_DASHBOARD.ViewTriage,
        icon: ICONS.triage,
      },
      {
        title: 'Clinic Directory',
        path: PATH_DASHBOARD.ViewClinicDirectory,
        icon: ICONS.chart,
      },
      {
        title: 'Queue Directory',
        path: PATH_DASHBOARD.ViewQueueDirectory,
        icon: ICONS.queue,
      },

      // WARDS
      {
        title: 'Wards',
        path: PATH_DASHBOARD.Wards.root,
        icon: ICONS.wards,
        children: [
          { title: 'Wards', path: PATH_DASHBOARD.Wards.Wards },
          { title: 'Room', path: PATH_DASHBOARD.Wards.Room },
          { title: 'Bed', path: PATH_DASHBOARD.Wards.Bed },
        ],
      },
      // WARDS END
      // CLINIC
      {
        title: 'Clinic',
        path: PATH_DASHBOARD.Clinic.root,
        icon: ICONS.clinic,
        children: [
          { title: 'Clinic', path: PATH_DASHBOARD.Clinic.Clinic },
          { title: 'Services', path: PATH_DASHBOARD.Clinic.Services },
        ],
      },
      // END CLINIC
      {
        title: 'Laboratory',
        path: PATH_DASHBOARD.ViewLaboratory,
        icon: ICONS.laboratory,
      },
      {
        title: 'Emergency',
        path: PATH_DASHBOARD.ViewEmergency,
        icon: ICONS.emergency,
      },
      {
        title: 'In-Patients',
        path: PATH_DASHBOARD.ViewIPD,
        icon: ICONS.inpatients,
      },
      {
        title: 'Out-Patients',
        path: PATH_DASHBOARD.ViewOPD,
        icon: ICONS.outpatient,
      },
      {
        title: 'Billing',
        path: PATH_DASHBOARD.ViewBilling,
        icon: ICONS.billing,
      },
      {
        title: 'Pharmacy',
        path: PATH_DASHBOARD.ViewPharmacy,
        icon: ICONS.pharmacy,
      },
      // MEDICAL RECORDS
      {
        title: 'Records Department',
        path: PATH_DASHBOARD.ViewRecordDept,
        icon: ICONS.medicalrec,
      },
      // END MEDICAL RECORDS
      {
        title: 'Patient Management',
        path: PATH_DASHBOARD.ViewPatientManagement,
        icon: ICONS.patient,
      },
      {
        title: 'Doctor Management',
        path: PATH_DASHBOARD.ViewDoctorManagement,
        icon: ICONS.doctor,
      },
      {
        title: 'Governors App',
        path: PATH_DASHBOARD.ViewGovApp,
        icon: ICONS.govview,
      },
      {
        title: 'DOH Statistics Report',
        path: PATH_DASHBOARD.ViewDohStatsReport,
        icon: ICONS.dohreport,
      },
      {
        title: 'Audit Trail',
        path: PATH_DASHBOARD.ViewAuditTrail,
        icon: ICONS.historylogs,
      },
      {
        title: 'File Manager',
        path: PATH_DASHBOARD.ViewFileManager,
        icon: ICONS.filemanager,
      },
      {
        title: 'Patient Survey',
        path: PATH_DASHBOARD.ViewPatientSurvey,
        icon: ICONS.patientsurvey,
      },
      // Inventory
      {
        title: 'Inventory',
        path: PATH_DASHBOARD.Inventory.root,
        icon: ICONS.clinic,
        children: [
          { title: 'Pharmacy', path: PATH_DASHBOARD.Inventory.Pharmacy },
          { title: 'Laboratory', path: PATH_DASHBOARD.Inventory.Laboratory },
          { title: 'Emergency', path: PATH_DASHBOARD.Inventory.Emergency },
          { title: 'Wards', path: PATH_DASHBOARD.Inventory.Wards },
          { title: 'Clinic', path: PATH_DASHBOARD.Inventory.ClinicInventory },
        ],
      },
      // --------------------------------
      // EXPENSE
      {
        title: 'Expense',
        path: PATH_DASHBOARD.Expense.root,
        icon: ICONS.expense,
        children: [
          { title: 'Expense', path: PATH_DASHBOARD.Expense.Expense },
          { title: 'Expense Category', path: PATH_DASHBOARD.Expense.ExpenseCategory },
        ],
      },

      // ================================
      {
        title: 'Settings',
        path: PATH_DASHBOARD.ViewSettings,
        icon: ICONS.settings,
      },
      //  END OF H.I.S
      // ----------------------------------------------------------------------------------------------
    ],
  },
  {
    subheader: 'Ward',
    items: [
      {
        title: 'Pedia',
        path: PATH_DASHBOARD.ViewPedia,
        icon: ICONS.ward,
      },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'Sample Pages',
  //   items: [
      // USER
      // {
      //   title: 'user',
      //   path: PATH_DASHBOARD.user.root,
      //   icon: ICONS.user,
      //   children: [
      //     { title: 'profile', path: PATH_DASHBOARD.user.profile },
      //     { title: 'cards', path: PATH_DASHBOARD.user.cards },
      //     { title: 'list', path: PATH_DASHBOARD.user.list },
      //     { title: 'create', path: PATH_DASHBOARD.user.new },
      //     { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
      //     { title: 'account', path: PATH_DASHBOARD.user.account },
      //   ],
      // },

      // ROUTE SAMPLE
      // {
      //   title: 'Sample',
      //   path: PATH_DASHBOARD.samplePage.root,
      //   icon: ICONS.file,
      //   children: [
      //     { title: 'subsample', path: PATH_DASHBOARD.samplePage.subsample, },
      //     { title: 'samplethree', path: PATH_DASHBOARD.samplePage.SampleThree },

      //   ],
      // },
      // SINGLE ITEM ON DASHBOARD
      // {
      //   title: 'ActualPage',
      //   path: PATH_DASHBOARD.ActualPage,
      //   icon: ICONS.mail,
      // },

      // E-COMMERCE
      // {
      //   title: 'ecommerce',
      //   path: PATH_DASHBOARD.eCommerce.root,
      //   icon: ICONS.cart,
      //   children: [
      //     { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
      //     { title: 'product', path: PATH_DASHBOARD.eCommerce.demoView },
      //     { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
      //     { title: 'create', path: PATH_DASHBOARD.eCommerce.new },
      //     { title: 'edit', path: PATH_DASHBOARD.eCommerce.demoEdit },
      //     { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
      //   ],
      // },

      // INVOICE
      // {
      //   title: 'invoice',
      //   path: PATH_DASHBOARD.invoice.root,
      //   icon: ICONS.invoice,
      //   children: [
      //     { title: 'list', path: PATH_DASHBOARD.invoice.list },
      //     { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
      //     { title: 'create', path: PATH_DASHBOARD.invoice.new },
      //     { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
      //   ],
      // },

      // BLOG
      // {
      //   title: 'blog',
      //   path: PATH_DASHBOARD.blog.root,
      //   icon: ICONS.blog,
      //   children: [
      //     { title: 'posts', path: PATH_DASHBOARD.blog.posts },
      //     { title: 'post', path: PATH_DASHBOARD.blog.demoView },
      //     { title: 'create', path: PATH_DASHBOARD.blog.new },
      //   ],
      // },
      // {
      //   title: 'File manager',
      //   path: PATH_DASHBOARD.fileManager,
      //   icon: ICONS.folder,
      // },
  //   ],
  // },

  // APP
  // ----------------------------------------------------------------------
  // {
    // subheader: 'app',
    // items: [
    //   {
    //     title: 'mail',
    //     path: PATH_DASHBOARD.mail.root,
    //     icon: ICONS.mail,
    //     info: <Label color="error">+32</Label>,
    //   },
    //   {
    //     title: 'chat',
    //     path: PATH_DASHBOARD.chat.root,
    //     icon: ICONS.chat,
    //   },
    //   {
    //     title: 'calendar',
    //     path: PATH_DASHBOARD.calendar,
    //     icon: ICONS.calendar,
    //   },
    //   {
    //     title: 'kanban',
    //     path: PATH_DASHBOARD.kanban,
    //     icon: ICONS.kanban,
    //   },
    // ],
  // },

  // DEMO MENU STATES
  // {
  //   subheader: 'Other cases',
  //   items: [
  //     {
  //       // default roles : All roles can see this entry.
  //       // roles: ['user'] Only users can see this item.
  //       // roles: ['admin'] Only admin can see this item.
  //       // roles: ['admin', 'manager'] Only admin/manager can see this item.
  //       // Reference from 'src/guards/RoleBasedGuard'.
  //       title: 'item_by_roles',
  //       path: PATH_DASHBOARD.permissionDenied,
  //       icon: ICONS.lock,
  //       roles: ['admin'],
  //       caption: 'only_admin_can_see_this_item',
  //     },
  //     {
  //       title: 'menu_level',
  //       path: '#/dashboard/menu_level',
  //       icon: ICONS.menuItem,
  //       children: [
  //         {
  //           title: 'menu_level_2a',
  //           path: '#/dashboard/menu_level/menu_level_2a',
  //         },
  //         {
  //           title: 'menu_level_2b',
  //           path: '#/dashboard/menu_level/menu_level_2b',
  //           children: [
  //             {
  //               title: 'menu_level_3a',
  //               path: '#/dashboard/menu_level/menu_level_2b/menu_level_3a',
  //             },
  //             {
  //               title: 'menu_level_3b',
  //               path: '#/dashboard/menu_level/menu_level_2b/menu_level_3b',
  //               children: [
  //                 {
  //                   title: 'menu_level_4a',
  //                   path: '#/dashboard/menu_level/menu_level_2b/menu_level_3b/menu_level_4a',
  //                 },
  //                 {
  //                   title: 'menu_level_4b',
  //                   path: '#/dashboard/menu_level/menu_level_2b/menu_level_3b/menu_level_4b',
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       title: 'item_disabled',
  //       path: '#disabled',
  //       icon: ICONS.disabled,
  //       disabled: true,
  //     },

  //     {
  //       title: 'item_label',
  //       path: '#label',
  //       icon: ICONS.label,
  //       info: (
  //         <Label color="info" startIcon={<Iconify icon="eva:email-fill" />}>
  //           NEW
  //         </Label>
  //       ),
  //     },
  //     {
  //       title: 'item_caption',
  //       path: '#caption',
  //       icon: ICONS.menuItem,
  //       caption:
  //         'Quisque malesuada placerat nisl. In hac habitasse platea dictumst. Cras id dui. Pellentesque commodo eros a enim. Morbi mollis tellus ac sapien.',
  //     },
  //     {
  //       title: 'item_external_link',
  //       path: 'https://www.google.com/',
  //       icon: ICONS.external,
  //     },
  //     {
  //       title: 'blank',
  //       path: PATH_DASHBOARD.blank,
  //       icon: ICONS.blank,
  //     },
  //   ],
  // },
];

export default navConfig;

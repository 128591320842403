import React, { useState, useEffect, useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import { color } from '@mui/system';

import {
  Box,
  Divider,
  Typography,
  Grid,
  Stack,
  Button,
  Avatar,
  TextField,
  FormControlLabel,
  Radio,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  Checkbox,
} from '@mui/material';
// COMPONENTS
import ConfirmDialog from '../../../../components/confirm-dialog/ConfirmDialog';
import Preview from './Preview';
import Label from '../../../../components/label/Label';

function Archive() {
  // VIEW TRIAGE
  const [openAD, setOpenAD] = useState(false);

  const handleCloseAD = () => {
    setOpenAD(false);
  };
  const handleOpenAD = () => {
    setOpenAD(true);
  };
  return (
    <>
      <Grid container spacing={1} sx={{ mb: 2, alignItems: 'center' }}>
        <Grid item md={10}>
          <Typography variant="h5" sx={{ color: '#B72136' }}>
            Archived Patient #Patient ID.
          </Typography>
        </Grid>
        <Grid
          item
          md={2}
          sx={{ display: 'flex', justifyContent: 'flex-end', justifyItems: 'flex-end' }}
        >
          &nbsp;
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={1}>
        <Grid container spacing={1} sx={{ mt: 2, alignItems: 'center' }}>
          <Grid item md={6}>
            <Box sx={{ ml: 1, display: 'flex', alignItems: 'center' }}>
              <Avatar alt="" sx={{ mr: 2, width: '4rem', height: '4rem' }}>
                AV
              </Avatar>

              <Stack>
                <Typography variant="h6" noWrap>
                  Patient Full Name
                </Typography>

                <Typography
                  noWrap
                  variant="body1"
                  sx={{ color: 'text.disabled', cursor: 'pointer' }}
                >
                  Patient ID
                </Typography>
              </Stack>
            </Box>
          </Grid>
          <Grid
            item
            md={6}
            sx={{ display: 'flex', justifyContent: 'flex-end', justifyItems: 'flex-end' }}
          >
            <Button variant="outlined" color="inherit" onClick={handleOpenAD}>
              Summarization
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={12}>
        <Box sx={{ maxWidth: '100%', mt: 1 }}>
          <TextField
            id=""
            label="Remarks of Declining"
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            maxRows={3}
            color="error"
            helperText="Please fill in the remarks"
            error="Not Yet Filled"
          />
        </Box>
      </Grid>

      <Grid item md={12}>
        <Stack
          spacing={1}
          direction="row"
          sx={{
            mt: 2,
            justifyContent: 'flex-end',
            justifyItems: 'flex-end',
            alignItems: 'flex-end',
          }}
        >
          <Button variant="outlined" color="inherit" onClick={handleCloseAD}>
            Close
          </Button>
          <Button type="submit" variant="contained" color="error">
            Save Changes
          </Button>
        </Stack>
      </Grid>

      {/* Preview Triage Record Confirmation Dialog */}
      <ConfirmDialog
        open={openAD}
        onClose={handleCloseAD}
        content={
          <Preview
          // handleClose={handleCloseAD}
          // fetchTable={fetchTable}
          // handleChipValue={handleChipValue}
          />
        }
      />
    </>
  );
}

export default Archive;

// React
// React
import React from 'react';
import PropTypes from 'prop-types';

// MUI
import { Box, Typography, Stack } from '@mui/material';

// Components
import Image from 'mui-image';
import Iconify from '../../../components/iconify/Iconify';
import Scrollbar from '../../../components/scrollbar/Scrollbar';

function DispensaryGreenCardBack() {
  // { form }
  const styles = {
    paperContainer: {
      backgroundImage: `url(${'/assets/images/HIS/cropped_cnph_opacity.png'})`,
      backgroundColor: 'rgba(6, 80, 39, 0.4)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      width: '100mm',
      height: '60mm',
    },
  };

  //   console.log('form', form?.patient_qr);

  //   const regexUrl = /\b(http|https)/;
  //   const imageQRCode = regexUrl.test(form?.patient_qr)
  //     ? form?.patient_qr
  //     : `${process.env.REACT_APP_HOST_API_KEY}${form?.patient_qr}`;

  return (
    <>
      <Box
        style={styles.paperContainer}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '16px',
        }}
      >
        <Stack spacing={2}>
          <Box>
            <Image
              src="/assets/images/HIS/qr.png"
              height="150px"
              width="150px"

              //   sx={{
              //     borderRadius: '16px',
              //     width: '15px',
              //     height: '15px',
              //     opacity: 0.9,
              //     bgcolorL: 'red',
              //   }}
            />
            <Typography sx={{ fontSize: '0.8rem', textAlign: 'center' }}>
              Scan QR for Information
            </Typography>
          </Box>
        </Stack>
      </Box>
    </>
  );
}

export default DispensaryGreenCardBack;

import React, { useState } from 'react';
import { Fab, Card, Typography, Stack, Container, Box, Button, Link } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Image } from 'mui-image';
// COMPONENTS
import ConfirmDialog from '../../../components/confirm-dialog/ConfirmDialog';
import AddUser from './Components/AddUser';

function ViewSignUp() {
  // HOOKS
  const [openAD, setOpenAD] = useState(false);
  const handleCloseAD = () => {
    setOpenAD(false);
  };
  const handleOpenAD = () => {
    setOpenAD(true);
  };
  return (
    <>
      <Container sx={{ alignItems: 'center' }}>
        <Helmet>
          <title>H.I.S | Signup</title>
        </Helmet>
        {/* <Accordion sx={{ color: '#05805f' }}>
          <AccordionSummary
            expandIcon={<Image src="/assets/images/HIS/expandmore.png" width={10} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              SIGN UP: How to Register Account
            </Typography>
          </AccordionSummary>
          <AccordionDetails> */}
        {/* <Stack >
              <Image src='/steps/step-one.png' width={500} />
            </Stack>  */}

        <Stack spacing={1}>
          <Card sx={{ p: 2 }}>
            <Typography variant="h6">
              1. A one-time registration Set Up for Administrator. Log your admin Account. Type on
              your browser.
            </Typography>

            <Box sx={{ p: 2 }}>
              <Link
                href="https://his.8nergyit.com/dashboard/user/management"
                target="_blank"
                rel="noopener"
                underline="none"
                sx={{ color: '#000000' }}
              >
              
                <Button variant="contained" color="primary">
                  https://his.8nergyit.com/dashboard/user/management
                </Button>
              </Link>
            </Box>
          </Card>
          <Card sx={{ p: 2 }}>
            <Typography variant="h6">
              2. Go to User Management Module and click the User Management sub-module.
              <Stack sx={{ alignItems: 'center' }}>
                <Image src="/assets/images/HIS/Signup/SignUp.PNG" width={300} />
              </Stack>
            </Typography>
          </Card>
          <Card sx={{ m: 2, p: 2 }}>
            <Typography variant="h6">
              3. Click the
              <Button
                variant="contained"
                onClick={handleOpenAD}
                sx={{ mx: 1, bgcolor: '#00AB55', fontWeight: 'bolder' }}
              >
                Add
              </Button>
              Button , After typing the necessary information wait for
              <Button>
                <Image src="/assets/images/HIS/User.PNG" width={300} />
              </Button>
              this to pop up in the upper right on your screen
            </Typography>
          </Card>
          <Card sx={{ m: 2, p: 2 }}>
            <Typography variant="h6">
              4. Then Click Submit.
              <Button
                variant="contained"
                sx={{ mx: 0.8, my: 1, bgcolor: '#00AB55', fontWeight: 'bolder' }}
              >
                Submit
              </Button>
            </Typography>
          </Card>
          <Card sx={{ m: 2, p: 2 }}>
            <Typography variant="h6">
              5. You can check the User Management table if you want to confirm and edit the account
              you created.
            </Typography>
          </Card>
        </Stack>
        {/* </AccordionDetails>
        </Accordion> */}
      </Container>
      <ConfirmDialog
        open={openAD}
        onClose={handleCloseAD}
        content={
          <AddUser
          // handleClose={handleCloseAD}
          // fetchTable={fetchTable}
          // handleChipValue={handleChipValue}
          />
        }
      />
    </>
  );
}

export default ViewSignUp;

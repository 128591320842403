import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
// React
import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

// MUI
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Divider,
  Typography,
  Grid,
  Stack,
  TextField,
  IconButton,
  Stepper,
  Step,
  StepLabel,
  Checkbox,
  Button,
  Card,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  Chip,
  InputAdornment,
  Container,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { addDays, addMonths, format } from 'date-fns';

// Components
import Label from '../../../../components/label/Label';
import ConfirmDialog from '../../../../components/confirm-dialog/ConfirmDialog';
import Iconify from '../../../../components/iconify/Iconify';
import Scrollbar from '../../../../components/scrollbar/Scrollbar';
// Sections
import GreenCardPreview from '../../RecordsDeptProfile/GreenCardPreview';
import UnpaidBilling from './UnpaidBilling';

Add.propTypes = {
  handleClose: PropTypes.func,
  filterTodayDate: PropTypes.instanceOf(Date),
  onFilterTodayDate: PropTypes.func,
  type: PropTypes.func,
};

const INPUT_WIDTH = 180;

function Add({ type }) {
  // { data, handleClose, filterTodayDate, onFilterTodayDate, type, handleDataTable }
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  //   console.log(data);
  const today = Date.now();
  const minDate = addDays(today, 1);
  const maxDate = addMonths(minDate, 6);

  // Patient Type
  const PA_OPTIONS = [
    { value: 'Normal', label: <Typography variant="body1">Normal</Typography> },
    { value: 'Impared', label: <Typography variant="body1">Impared</Typography> },
    { value: 'Hearing', label: <Typography variant="body1">Hearing</Typography> },
  ];

  // Stepper
  const steps = [
    'Validate IPD (In-Patient Department)',
    'Fill-In Triage Form',
    'Confirmation of Patient and Triage Form',
  ];
  const [confirmData, setConfirmData] = React.useState({});
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [isShow, setIsShow] = React.useState(false);
  const [form, setForm] = useState({});

  // Modals
  //   ID Preview
  const [openGREENCARD, setOpenGREENCARD] = useState(false);

  const handleClickOpenGREENCARD = () => {
    setOpenGREENCARD(true);
  };

  const handleCloseGREENCARD = () => {
    setOpenGREENCARD(false);
  };
  //   Unpaid Bill
  const [openUNPAIDBILL, setOpenUNPAIDBILL] = useState(false);

  const handleClickOpenUNPAIDBILL = () => {
    setOpenUNPAIDBILL(true);
  };

  const handleCloseUNPAIDBILL = () => {
    setOpenUNPAIDBILL(false);
  };

  return (
    // <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
    <>
      <Container sx={{ p: 1 }}>
        <Grid container spacing={1} sx={{ mb: 2, alignItems: 'center' }}>
          <Grid item md={9}>
            <Typography variant="h4">Validate IPD (In-Patient Department) </Typography>
          </Grid>
          <Grid item md={3} display="flex" justifyContent="flex-end" alignItems="center">
            <IconButton
              size="small"
              sx={{ border: '2px solid', color: 'red' }}
              // onClick={handleClose}
            >
              <Iconify icon="ion:close" width={30} height={30} sx={{ color: 'red' }} />
            </IconButton>
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={1}>
          <Grid container spacing={1} sx={{ mt: 2, alignItems: 'center' }}>
            {type !== 'View' && (
              <Grid item md={12} sx={{ my: 2, ml: 0.2 }}>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                  <Box sx={{ width: '80%' }}>
                    <Stepper>
                      {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        //   if (isStepSkipped(index)) {
                        //     stepProps.completed = false;
                        //   }
                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                  </Box>
                </Stack>
              </Grid>
            )}
            {/* STEP1 */}
            <Card sx={{ p: 2, mt: 2, width: 1500 }}>
              <Typography variant="h6">1. Fill-Out the necessary information</Typography>

              {/* <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}> */}
              <Grid item xs={12} sm={12} md={12}>
                {/* <TextField id="" label="Search for Existing Patient Name or Patient Number" variant="outlined" fullWidth /> */}
                <TextField
                  freeSolo
                  name="patient_details"
                  label="Search for Existing Patient Name or Patient Number"
                  variant="outlined"
                  fullWidth
                  // options={searchPatient.map((option) => option)}
                  // onChange={searchPatientHandle}
                  // getOptionLabel={(option) =>
                  //   ` ${
                  //     option?.full_name ||
                  //     `${option?.last_name}, ${option?.first_name} ${option.last_name}`
                  //   } - (${option.status})` || ''
                  // }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Grid
                  container
                  spacing={{ xs: 1, md: 2 }}
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      fullWidth
                      // disabled={Object.keys(form).length === 0}
                      sx={{ mt: 1.2, bgcolor: 'gray' }}
                      // onClick={handleClearPatient}
                    >
                      Clear
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      fullWidth
                      // disabled={Object.keys(form).length === 0}
                      sx={{ mt: 1.2, bgcolor: 'orange' }}
                      onClick={handleClickOpenGREENCARD}
                    >
                      View ID
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{ mt: 1.2 }}
                      onClick={handleClickOpenUNPAIDBILL}
                    >
                      Search
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      color="error"
                      variant="contained"
                      // disabled={Object.keys(form).length !== 0}
                      fullWidth
                      sx={{ mt: 1.2 }}
                      // onClick={() => {
                      //   setValue('patient_details', '');
                      //   handleClickOpenScanQR();
                      // }}
                    >
                      Scan
                    </Button>
                  </Grid>
                </Grid>
                <Stack direction="row" spacing={1} alignItems="center" sx={{ mt: 1 }}>
                  <Box sx={{ flexGrow: 1 }} />
                  <Box>
                    <Button
                      variant="contained"
                      color="primary"
                      // onClick={() => {
                      //   setIsShow(true);
                      // }}
                    >
                      Create IPD Patient Data
                    </Button>
                  </Box>
                </Stack>
              </Grid>
              <Box sx={{ pl: 4 }}>
                <Typography variant="h6" sx={{ mt: 1 }}>
                  • Click
                  <Button
                    variant="contained"
                    // disabled={Object.keys(form).length === 0}
                    sx={{ bgcolor: 'orange', mx: 1 }}
                    onClick={handleClickOpenGREENCARD}
                  >
                    View ID
                  </Button>
                  to view Greencard ID
                </Typography>

                <Typography variant="h6" sx={{ mt: 1 }}>
                  <Stack direction="row">
                    • Click
                    <Button variant="contained" sx={{ mx: 1 }} onClick={handleClickOpenUNPAIDBILL}>
                      Search
                    </Button>
                    to Search the Patient.
                    <Typography variant="h6" sx={{ ml: 0.4 }}>
                      <Stack direction="row">
                        If there is an
                        <Typography variant="h6" sx={{ color: '#FF5630', mx: 1 }}>
                          Unpaid Bill
                        </Typography>
                        a modal would Pop-Up to show the Bill
                      </Stack>
                    </Typography>
                  </Stack>
                </Typography>
                <Typography variant="h6" sx={{ mt: 1 }}>
                  • Click
                  <Button
                    color="error"
                    variant="contained"
                    // disabled={Object.keys(form).length !== 0}

                    sx={{ mx: 1 }}
                    // onClick={() => {
                    //   setValue('patient_details', '');
                    //   handleClickOpenScanQR();
                    // }}
                  >
                    Scan
                  </Button>
                  to Scan ID
                </Typography>
                <Typography variant="h6" sx={{ mt: 1 }}>
                  • Click
                  <Button
                    variant="contained"
                    color="primary"
                    // onClick={() => {
                    //   setIsShow(true);
                    // }}
                    sx={{ mx: 1 }}
                  >
                    Create IPD Patient Data
                  </Button>
                  to Create IPD, After Clicking the button the Forms would appear that can be
                  filled-in
                </Typography>
              </Box>

              <Grid item xs={12} sm={12} md={12}>
                <Divider fullWidth sx={{ my: 2 }}>
                  <Chip label="Patient Details" />
                </Divider>
              </Grid>
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={12} sm={4} md={4}>
                  <TextField
                    id="last_name"
                    name="last_name"
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    // value={form?.last_name || ''}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <TextField
                    id="first_name"
                    name="first_name"
                    label="First Name"
                    variant="outlined"
                    fullWidth
                    // value={form?.first_name || ''}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <TextField
                    id="middle_name"
                    name="middle_name"
                    label="Middle Name"
                    variant="outlined"
                    fullWidth
                    // value={form?.middle_name || ''}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      sx={{
                        mb: {
                          xs: 2,
                        },
                      }}
                    >
                      <TextField
                        id="age"
                        name="age"
                        label="Age "
                        variant="outlined"
                        fullWidth
                        // value={form?.age || ''}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        id="gender"
                        name="gender"
                        label="Gender"
                        variant="outlined"
                        fullWidth
                        // value={form?.gender || ''}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={4} md={4}>
                  <TextField
                    id="civil_status"
                    name="civil_status"
                    label="Civil Status"
                    variant="outlined"
                    fullWidth
                    // value={form?.civil_status || ''}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <TextField
                    id="date_of_birth"
                    name="date_of_birth"
                    label="Date of Birth"
                    variant="outlined"
                    fullWidth
                    // value={form?.date_of_birth || form?.birth_date || ''}
                  />
                </Grid>

                <Grid item xs={12} sm={4} md={4}>
                  <TextField
                    id="address"
                    name="house_no"
                    label="House Number"
                    variant="outlined"
                    fullWidth
                    // value={form?.house_no || form?.house_number || ''}
                  />
                </Grid>

                <Grid item xs={12} sm={4} md={4}>
                  <TextField
                    id="address"
                    name="street"
                    label="Street"
                    variant="outlined"
                    fullWidth
                    // value={form?.street || ''}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <TextField
                    id="region"
                    name="region"
                    label="Region"
                    variant="outlined"
                    fullWidth
                    // value={form?.region_name || form?.region || ''}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <TextField
                    id="province"
                    name="province"
                    label="Province"
                    variant="outlined"
                    fullWidth
                    // value={form?.province_name || form?.province || ''}
                  />
                </Grid>

                <Grid item xs={12} sm={4} md={4}>
                  <TextField
                    id="city"
                    name="city"
                    label="City"
                    variant="outlined"
                    fullWidth
                    // value={form?.city_name || form?.city || ''}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <TextField
                    id="barangay"
                    name="barangay"
                    label="Barangay"
                    variant="outlined"
                    fullWidth
                    // value={form?.barangay_name || form?.barangay || ''}
                  />
                </Grid>
                <Grid item md={12} sx={{ mt: 1 }}>
                  <Typography variant="h6">
                    Click{' '}
                    <Button
                      variant="outlined"
                      color="inherit"
                      sx={{ mx: 1 }}
                      //   onClick={handleBack}
                    >
                      Back
                    </Button>
                    or
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ mx: 1 }}
                      //    loading={isSubmitting}
                    >
                      Proceed
                    </Button>
                    If you are done filling out the necessary information
                  </Typography>
                  <Stack
                    spacing={1}
                    direction="row"
                    sx={{
                      mt: 2,
                      justifyContent: 'flex-end',
                      justifyItems: 'flex-end',
                      alignItems: 'flex-end',
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="inherit"
                      //   onClick={handleBack}
                    >
                      Back
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      //    loading={isSubmitting}
                    >
                      Proceed
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
              {/* </Grid> */}
            </Card>
            {/* END OF STEP1 */}

            {/* STEP2  */}
            <Card sx={{ p: 2, mt: 2, width: 1500 }}>
              <Typography variant="h6">2. Fill-Out the Triage Form </Typography>
              <Grid item md={12} sx={{ mt: 2 }}>
                <Typography variant="h6">Referred By:</Typography>
                <Grid container spacing={1} sx={{ mt: 1 }}>
                  <Grid row item md={8} sx={{ display: 'grid', justifyContent: 'center' }}>
                    {/* <RHFRadioGroup  name="referred_by" defaultValue="self" sx={{ width: '50%' }} options={REFERRED_TYPE_OPTIONS}/> */}

                    <RadioGroup row name="referred_by" container spacing={1}>
                      <Grid item md={4} sx={{ display: 'grid', justifyContent: 'center' }}>
                        <Grid item md={12}>
                          <FormControlLabel
                            value="self"
                            control={<Radio />}
                            label={<Typography variant="body1">Self</Typography>}
                          />
                        </Grid>
                        <Grid item md={12}>
                          <FormControlLabel
                            value="opd"
                            control={<Radio />}
                            label={<Typography variant="body1">OPD</Typography>}
                          />
                        </Grid>
                        <Grid item md={12}>
                          <FormControlLabel
                            value="pri-vmd"
                            control={<Radio />}
                            label={<Typography variant="body1">Priv MD</Typography>}
                          />
                        </Grid>
                      </Grid>
                      <Grid item md={4} sx={{ display: 'grid', justifyContent: 'center' }}>
                        <Grid item md={12}>
                          <FormControlLabel
                            value="hospital"
                            control={<Radio />}
                            label={<Typography variant="body1">Hospital</Typography>}
                          />
                        </Grid>
                        <Grid item md={12}>
                          <FormControlLabel
                            value="ambulance"
                            control={<Radio />}
                            label={<Typography variant="body1">Ambulance</Typography>}
                          />
                        </Grid>
                        <Grid item md={12}>
                          <FormControlLabel
                            value="walk-in"
                            control={<Radio />}
                            label={<Typography variant="body1">Walk-In</Typography>}
                          />
                        </Grid>
                      </Grid>
                      <Grid item md={4} sx={{ display: 'grid', justifyContent: 'center' }}>
                        <Grid item md={12}>
                          <FormControlLabel
                            value="private-vehicle"
                            control={<Radio />}
                            label={<Typography variant="body1">Private Vehicle</Typography>}
                          />
                        </Grid>
                        <Grid item md={12}>
                          <FormControlLabel
                            value="police-escort"
                            control={<Radio />}
                            label={<Typography variant="body1">Police Escort</Typography>}
                          />
                        </Grid>
                        <Grid item md={12} sx={{ mt: 1 }}>
                          <TextField label="Other" />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </Grid>
                  <Grid item md={2} sx={{ display: 'grid', justifyContent: 'center' }}>
                    <Grid item md={12}>
                      <Typography variant="h6">SMOKING</Typography>
                    </Grid>
                    <RadioGroup row id="smoking" name="smoking" sx={{ width: '50%' }}>
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label={<Typography variant="body1">Yes</Typography>}
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label={<Typography variant="body1">No</Typography>}
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item md={2} sx={{ display: 'grid', justifyContent: 'center' }}>
                    <Grid item md={12}>
                      <Typography variant="h6">NEW BORN</Typography>
                    </Grid>
                    <RadioGroup row id="smoking" name="smoking" sx={{ width: '50%' }}>
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label={<Typography variant="body1">Yes</Typography>}
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label={<Typography variant="body1">No</Typography>}
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </Grid>
              {/* ----------------------------- */}
              {/* --------------------------------------------------------------------------- */}

              <Grid item md={12} sx={{ mt: 2 }}>
                <Typography variant="h6">Vital Signs:</Typography>
                <Grid container spacing={1} sx={{ mt: 1 }}>
                  <Grid item md={6} sx={{ display: 'grid', justifyContent: 'center' }}>
                    <Grid container spacing={1}>
                      <Grid item md={12}>
                        <TextField
                          id="bp"
                          name="bp"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            startAdornment: <InputAdornment position="start">BP</InputAdornment>,
                            endAdornment: <InputAdornment position="end">mmHg</InputAdornment>,
                            inputProps: { style: { textAlign: 'center' } },
                          }}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <TextField
                          id="hr"
                          name="hr"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            startAdornment: <InputAdornment position="start">HR</InputAdornment>,
                            endAdornment: <InputAdornment position="end">bpm</InputAdornment>,
                            inputProps: { style: { textAlign: 'center' } },
                          }}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <TextField
                          id="rr"
                          name="rr"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            startAdornment: <InputAdornment position="start">RR</InputAdornment>,
                            endAdornment: <InputAdornment position="end">bpm</InputAdornment>,
                            inputProps: { style: { textAlign: 'center' } },
                          }}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <TextField
                          id="temp"
                          name="temp"
                          variant="outlined"
                          fullWidth
                          type="number"
                          InputProps={{
                            startAdornment: <InputAdornment position="start">Temp</InputAdornment>,
                            endAdornment: <InputAdornment position="end">°C</InputAdornment>,
                            inputProps: { style: { textAlign: 'center' } },
                          }}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <TextField
                          id="weight"
                          name="weight"
                          variant="outlined"
                          fullWidth
                          type="number"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">Weight</InputAdornment>
                            ),
                            endAdornment: <InputAdornment position="end">kgs</InputAdornment>,
                            inputProps: { style: { textAlign: 'center' } },
                          }}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <TextField
                          id="height"
                          name="height"
                          variant="outlined"
                          fullWidth
                          type="number"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">Height</InputAdornment>
                            ),
                            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                            inputProps: { style: { textAlign: 'center' } },
                          }}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <TextField
                          id="O2Sat"
                          name="O2sat"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">O2 Sat</InputAdornment>
                            ),
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            inputProps: { style: { textAlign: 'center' } },
                          }}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <Grid container spacing={1}>
                          <Grid item md={6}>
                            <TextField
                              id="g"
                              name="g"
                              variant="outlined"
                              fullWidth
                              InputProps={{
                                startAdornment: <InputAdornment position="start">G</InputAdornment>,
                                inputProps: { style: { textAlign: 'center' } },
                              }}
                            />
                          </Grid>
                          <Grid item md={6}>
                            <TextField
                              id="p"
                              name="p"
                              variant="outlined"
                              fullWidth
                              InputProps={{
                                startAdornment: <InputAdornment position="start">P</InputAdornment>,
                                inputProps: { style: { textAlign: 'center' } },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item md={6} sx={{ display: 'grid', justifyContent: 'center' }}>
                    <Grid container spacing={1}>
                      <Grid item md={12}>
                        <TextField
                          id="bp"
                          name="bp"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            startAdornment: <InputAdornment position="start">BP</InputAdornment>,
                            endAdornment: <InputAdornment position="end">mmHg</InputAdornment>,
                            inputProps: { style: { textAlign: 'center' } },
                          }}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <TextField
                          id="bp"
                          name="bp"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            startAdornment: <InputAdornment position="start">LMP</InputAdornment>,
                            // endAdornment: <InputAdornment position="end">mmHg</InputAdornment>,
                            inputProps: { style: { textAlign: 'center' } },
                          }}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <TextField
                          id="hr"
                          name="hr"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            startAdornment: <InputAdornment position="start">EDC</InputAdornment>,
                            // endAdornment: <InputAdornment position="end">bpm</InputAdornment>,
                            inputProps: { style: { textAlign: 'center' } },
                          }}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <TextField
                          id="rr"
                          name="rr"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            startAdornment: <InputAdornment position="start">AOG</InputAdornment>,
                            endAdornment: <InputAdornment position="end">wks</InputAdornment>,
                            inputProps: { style: { textAlign: 'center' } },
                          }}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <TextField
                          id="temp"
                          name="temp"
                          variant="outlined"
                          fullWidth
                          type="number"
                          InputProps={{
                            startAdornment: <InputAdornment position="start">FHT</InputAdornment>,
                            endAdornment: <InputAdornment position="end">bpm</InputAdornment>,
                            inputProps: { style: { textAlign: 'center' } },
                          }}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <TextField
                          id="weight"
                          name="weight"
                          variant="outlined"
                          fullWidth
                          type="number"
                          InputProps={{
                            startAdornment: <InputAdornment position="start">FH</InputAdornment>,
                            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                            inputProps: { style: { textAlign: 'center' } },
                          }}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <TextField
                          id="height"
                          name="height"
                          variant="outlined"
                          fullWidth
                          type="number"
                          InputProps={{
                            startAdornment: <InputAdornment position="start">IE</InputAdornment>,
                            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                            inputProps: { style: { textAlign: 'center' } },
                          }}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <TextField
                          id="O2Sat"
                          name="O2sat"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">MENARCHE</InputAdornment>
                            ),
                            // endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            inputProps: { style: { textAlign: 'center' } },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* --------------------------------------------- */}
              <Grid item md={12} sx={{ mt: 2 }}>
                <Typography variant="h6">Chief Complaint:</Typography>
                <Grid container spacing={1} sx={{ mt: 0.5 }}>
                  <Grid item md={12}>
                    <TextField
                      id="chief_complaint"
                      name="chief_complaint"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={3}
                      maxRows={3}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* -------------------------------- */}

              <Grid item md={12} sx={{ mt: 2 }}>
                <Grid container spacing={1}>
                  <Grid item md={4}>
                    <Typography variant="h6" sx={{ mb: 1 }}>
                      Past Medical:
                    </Typography>

                    <Grid item md={12}>
                      <Grid container spacing={1}>
                        {/* <RHFMultiCheckbox name="past_medical" options={FILTER_GENDER_OPTIONS} sx={{ width: 1 }} /> */}
                        {/* <RHFCheckbox  label="Protect from light" name="past_medical" /> */}

                        {/*       
                      <Grid item md={6}>
                        <FormControlLabel
                          value="none"
                          control={<Checkbox />}
                          label={<Typography variant="body1">None</Typography>}
                          sx={{ ml: 2 }}
                        />
                        <FormControlLabel
                          value="diabetes"
                          control={<Checkbox />}
                          label={<Typography variant="body1">Diabetes</Typography>}
                          sx={{ ml: 2 }}
                        />
                        <FormControlLabel
                          value="asthma"
                          control={<Checkbox />}
                          label={<Typography variant="body1">Asthma</Typography>}
                          sx={{ ml: 2 }}
                        />
                        <FormControlLabel
                          value="icu admission"
                          control={<Checkbox />}
                          label={<Typography variant="body1">ICU Admission</Typography>}
                          sx={{ ml: 2 }}
                        />
                      </Grid>
                      <Grid item md={6}>
                        <FormControlLabel
                          value="ptb"
                          control={<Checkbox />}
                          label={<Typography variant="body1">PTB</Typography>}
                          sx={{ ml: 2 }}
                        />
                        <FormControlLabel
                          value="hypertension"
                          control={<Checkbox />}
                          label={<Typography variant="body1">Hypertension</Typography>}
                          sx={{ ml: 2 }}
                        />
                        <FormControlLabel
                          value="cardiac"
                          control={<Checkbox />}
                          label={<Typography variant="body1">Cardiac</Typography>}
                          sx={{ ml: 2 }}
                        />
                        <FormControlLabel
                          value="other"
                          control={<Checkbox />}
                          label={<Typography variant="body1">Other</Typography>}
                          sx={{ ml: 2 }}
                        />
                      </Grid> 
                 
                      {/* <Grid item md={6}>
              <RHFMultiCheckbox
                row
                name="past_medical"
                // label="RHFMultiCheckbox"

                options={[
                  { value: 'none',   label:  <Typography variant="body1">None</Typography>},
                  { value: 'diabetes', label:  <Typography variant="body1">Diabetes</Typography>},
                  { value: 'asthma',   label:  <Typography variant="body1">Asthma</Typography>},
                  { value: 'icu admission',   label:  <Typography variant="body1">ICU Admission</Typography>},
                 
                ]}
              />
              
                </Grid> */}

                        {/* <Grid item md={6}>
              <RHFMultiCheckbox
                row
                name="past_medical"
                // label="RHFMultiCheckbox"

                options={[
                  { value: 'ptb',   label:  <Typography variant="body1">PTB</Typography>},
                  { value: 'hypertension', label:  <Typography variant="body1">Hypertension</Typography>},
                  { value: 'cardiac',   label:  <Typography variant="body1">Cardiac</Typography>},
                  { value: 'other',   label:  <Typography variant="body1">Other</Typography>},
                 
                ]}
              />
              
                </Grid> */}
                        {/* </RHFCheckbox> */}

                        <Grid item md={6}>
                          <FormControlLabel
                            value="none"
                            control={<Checkbox />}
                            label={<Typography variant="body1">None</Typography>}
                            // sx={{ ml: 2 }}
                          />
                          <FormControlLabel
                            value="diabetes"
                            control={<Checkbox />}
                            label={<Typography variant="body1">Diabetes</Typography>}
                            // sx={{ ml: 2 }}
                          />
                          <FormControlLabel
                            value="asthma"
                            control={<Checkbox />}
                            label={<Typography variant="body1">Asthma</Typography>}
                            // sx={{ ml: 2 }}
                          />
                          <FormControlLabel
                            value="icuadmission"
                            control={<Checkbox />}
                            label={<Typography variant="body1">ICU Admission</Typography>}
                            // sx={{ ml: 2 }}
                          />
                        </Grid>
                        <Grid item md={6}>
                          <FormControlLabel
                            value="ptb"
                            control={<Checkbox />}
                            label={<Typography variant="body1">PTB</Typography>}
                            // sx={{ ml: 2 }}
                          />
                          <FormControlLabel
                            value="hypertension"
                            control={<Checkbox />}
                            label={<Typography variant="body1">Hypertension</Typography>}
                            // sx={{ ml: 1 }}
                          />
                          <FormControlLabel
                            value="cardiac"
                            control={<Checkbox />}
                            label={<Typography variant="body1">Cardiac</Typography>}
                            // sx={{ ml: 2 }}
                          />
                          <FormControlLabel
                            value="other"
                            control={<Checkbox />}
                            label={<Typography variant="body1">Other</Typography>}
                            // sx={{ ml: 2 }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={8}>
                    <Typography variant="h6" sx={{ mb: 1 }}>
                      Medications:
                    </Typography>
                    <TextField
                      id="medication"
                      name="medication"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={6}
                      maxRows={5}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* ----------------------------------- */}
              <Grid item md={12} sx={{ mt: 4 }}>
                <Grid container spacing={1} sx={{ p: 1 }}>
                  <Grid item md={4} sx={{ border: '1px solid gainsboro', p: 2, ml: 0.5 }}>
                    <Typography variant="subtitle1" sx={{ color: '#696969' }}>
                      Triage Officer:
                    </Typography>
                    <Typography variant="h5" sx={{ textAlign: 'center', mt: 1 }}>
                      DR. JAKE THE DOG
                    </Typography>
                  </Grid>
                  <Grid item md={4} sx={{ border: '1px solid gainsboro', p: 2 }}>
                    <Typography variant="subtitle1" sx={{ color: '#696969' }}>
                      Date:
                    </Typography>
                    <Typography variant="h5" sx={{ textAlign: 'center', mt: 1, color: '#483D8B' }}>
                      May 16,2024
                    </Typography>
                  </Grid>
                  <Grid item md={4} sx={{ border: '1px solid gainsboro', p: 2, mr: -1 }}>
                    <Typography variant="subtitle1" sx={{ color: '#696969' }}>
                      Time:
                    </Typography>
                    <Typography variant="h5" sx={{ textAlign: 'center', mt: 1 }}>
                      11:46 am
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* ----------------------------------------- */}
              <Grid item md={12}>
                <Typography variant="h5" noWrap sx={{ ml: 0.5 }}>
                  Department:
                </Typography>
                <Box sx={{ flexGrow: 1 }} />

                <ToggleButtonGroup
                  // disabled={data?.deaths}
                  //   value={condition}
                  //   exclusive
                  //   onChange={handleCondition}
                  aria-label="text alignment"
                  sx={{
                    mt: 1,
                    width: '100%',
                    '& .MuiToggleButton-root.Mui-selected': {
                      color: 'primary.main',
                    },
                  }}
                >
                  <ToggleButton value="inpatient" aria-label="left aligned" sx={{ width: '50%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Iconify icon="material-symbols:inpatient-outline" width={40} height={40} />
                      <Typography variant="caption">In-Patient</Typography>
                    </Box>
                  </ToggleButton>

                  <ToggleButton value="emergency" aria-label="centered" sx={{ width: '50%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Iconify icon="ic:outline-emergency" width={40} height={40} />
                      <Typography variant="caption">Emergency</Typography>
                    </Box>
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              {/* ------------------------------------- */}
            </Card>
            {/*  */}
          </Grid>
        </Grid>
      </Container>
      {/* Modals */}
      {/* ID Preview */}
      <ConfirmDialog
        open={openGREENCARD}
        onClose={handleCloseGREENCARD}
        content={
          <GreenCardPreview
          // handleClose={handleCloseAD}
          // fetchTable={fetchTable}
          // handleChipValue={handleChipValue}
          />
        }
      />
      {/* Unpaid Billing */}
      <ConfirmDialog
        open={openUNPAIDBILL}
        onClose={handleCloseUNPAIDBILL}
        content={
          <UnpaidBilling
          // handleClose={handleCloseAD}
          // fetchTable={fetchTable}
          // handleChipValue={handleChipValue}
          />
        }
      />
      {/*  */}
    </>

    // </FormProvider>
  );
}

export default Add;

// React
import PropTypes from 'prop-types';

// MUI
import { Stack, InputAdornment, TextField } from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';

// Components
import Iconify from '../../../../components/iconify/Iconify';

// ----------------------------------------------------------------------

const INPUT_WIDTH = 160;

TriageTableToolbar.propTypes = {
  filterName: PropTypes.string,
  filterEndDate: PropTypes.instanceOf(Date),
  filterStartDate: PropTypes.instanceOf(Date),
  onFilterName: PropTypes.func,
  onFilterEndDate: PropTypes.func,
  onFilterStartDate: PropTypes.func,
  // optionsService: PropTypes.arrayOf(PropTypes.string),
};

export default function TriageTableToolbar({
  filterStartDate,
  filterEndDate,
  filterName,
  onFilterName,
  onFilterStartDate,
  onFilterEndDate,
}) {
  return (
    <Stack spacing={2} direction={{ xs: 'column', md: 'row' }} sx={{ py: 2.5, px: 3 }}>
      <TextField
        fullWidth
        value={filterName}
        onChange={(event) => onFilterName(event.target.value)}
        placeholder="Search Patient Name nor Patient Number ..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon="eva:search-fill"
                sx={{ color: 'text.disabled', width: 20, height: 20 }}
              />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
}
